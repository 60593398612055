export const amplitudeStringMapping = {
  appName: 'Core',
  cashReceiptForm: 'Cash Receipt Form'
};

const PDF_PAGE_ORITENTATION = 'landscape';
const PDF_WITH_HEADER_IMAGE = true;
const PDF_WITH_FOOTER_PAGE_COUNT = true;
const PDF_HEADER_HEIGHT = 25;
const PDF_ROW_HEIGHT = 15;

const PDF_ODD_BKG_COLOR = '#fcfcfc';
const PDF_EVEN_BKG_COLOR = '#fff';
const PDF_WITH_CELL_FORMATTING = true;
const PDF_WITH_COLUMNS_AS_LINKS = true;

const PDF_SELECTED_ROWS_ONLY = false;

const PDF_HEADER_COLOR = '#f8f8f8';
const PDF_INNER_BORDER_COLOR = '#dde2eb';
const PDF_OUTER_BORDER_COLOR = '#babfc7';
const PDF_LOGO = '';

export const printParams = {
  PDF_HEADER_COLOR,
  PDF_INNER_BORDER_COLOR,
  PDF_OUTER_BORDER_COLOR,
  PDF_LOGO,
  PDF_PAGE_ORITENTATION,
  PDF_WITH_HEADER_IMAGE,
  PDF_WITH_FOOTER_PAGE_COUNT,
  PDF_HEADER_HEIGHT,
  PDF_ROW_HEIGHT,
  PDF_ODD_BKG_COLOR,
  PDF_EVEN_BKG_COLOR,
  PDF_WITH_CELL_FORMATTING,
  PDF_WITH_COLUMNS_AS_LINKS,
  PDF_SELECTED_ROWS_ONLY
};
