import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@cdk-uip/react-avatar';
import { CardHeader, Card, CardTitle } from '@cdk-uip/react-card';
import { logNavigationEvent } from '@cdk-flex/components-flex-amplitude';
import { QuickLinks } from 'components/quick-links';

const ROOT_PATH = process.env.PUBLIC_URL;

/**
 * AppLinkCard component
 */
const AppLinkCard = ({ link, quicklinks, avatarText, text }) => {
  const openPage = () => {
    window.location = `${ROOT_PATH}/${link}`;
  };

  return (
    <Card>
      <button
        className="app-link-card"
        onClick={e => logNavigationEvent('dashboard', `/${link}`, openPage)}
      >
        <CardHeader avatar>
          <Avatar className="avatar">{avatarText}</Avatar>
          <CardTitle className="app-text">{text}</CardTitle>
        </CardHeader>
      </button>
      {quicklinks.length > 0 && <QuickLinks quicklinks={quicklinks} />}
    </Card>
  );
};

AppLinkCard.propTypes = {
  /* Text to display in the Card */
  text: PropTypes.string.isRequired,
  /* Text to display in the avatar in the Card */
  avatarText: PropTypes.string.isRequired,
  /* Link URL for navigation on Card click */
  link: PropTypes.string.isRequired,
  /* quicklinks: quick links for the card */
  quicklinks: PropTypes.arrayOf(PropTypes.any)
};

AppLinkCard.defaultProps = {
  quicklinks: []
};

export default AppLinkCard;
