/**
 * All permissions for Drive Flex
 */

export const USER_MGMT = {
  id: 'USER_MGMT',
  description: 'Add and Edit Users and User access and permissions'
};
export const ACCOUNT_SETUP = {
  id: 'ACCOUNT_SETUP',
  description: 'Setup Accounts'
};
export const PRINTER_SETUP = {
  id: 'PRINTER_SETUP',
  description: 'Setup printers'
};
export const CUST_INFO_TAXTAB = {
  id: 'CUST_INFO_TAXTAB',
  description: 'Tax Info(Permissions - Manage/Read Only)'
};
export const VEHICLE_INV = {
  id: 'VEHICLE_INV',
  description: 'Add & Maintain Vehicle Inventory'
};
export const ACCESS_GL = {
  id: 'ACCESS_GL',
  description:
    'Access General Ledger & create memo or journal entry to balance accounts'
};
export const CUST_INFO_ARCHARGETAB = {
  id: 'CUST_INFO_ARCHARGETAB',
  description: 'A/R Charge(Permissions - Manage/Read Only)'
};
export const CUSTOMER_INFO = {
  id: 'CUSTOMER_INFO',
  description: 'Add & Maintain Customer Information'
};
export const CPO = { id: 'CPO', description: 'Create Purchase Order' };
export const REVIEW_ACCT_BAL = {
  id: 'REVIEW_ACCT_BAL',
  description: 'Review Accounting Balances'
};
export const CUST_INFO_DETAILSTAB = {
  id: 'CUST_INFO_DETAILSTAB',
  description: 'Customer Details'
};
export const CUST_INFO_ROINFOTAB = {
  id: 'CUST_INFO_ROINFOTAB',
  description: 'RO Info'
};
export const ORDER_PARTS = { id: 'ORDER_PARTS', description: 'Order parts' };
export const RETURNS_INVOICE = {
  id: 'RETURNS_INVOICE',
  description: 'Create a returns invoice(refund/credit)'
};
export const PARTS_INVENTORY = {
  id: 'PARTS_INVENTORY',
  description: 'Add/edit parts inventory'
};
export const CREATE_INVOICE_QUOTE = {
  id: 'CREATE_INVOICE_QUOTE',
  description: 'Create an invoice quote'
};
export const SELL_PART_RO = {
  id: 'SELL_PART_RO',
  description: 'Sell a part ro repair order'
};
export const RECEIVE_PARTS = {
  id: 'RECEIVE_PARTS',
  description: 'Receive Parts'
};
export const PARTS_INVOICE = {
  id: 'PARTS_INVOICE',
  description: 'Create a Parts Invoice'
};
export const INVOICE_CUST_CHARGE = {
  id: 'INVOICE_CUST_CHARGE',
  description: 'Invoice account customers for charge sales'
};
export const SUBMIT_HOURS = {
  id: 'SUBMIT_HOURS',
  description: 'Submit repair hours for employee wages'
};
export const RO = { id: 'RO', description: 'Create a Repair Order' };
export const FIND_PARTS = {
  id: 'FIND_PARTS',
  description: 'Find and Order Parts'
};
export const APPOINTMENTS = {
  id: 'APPOINTMENTS',
  description: 'Schedule and Cancel Appointments'
};
export const PROFILE_REFUND = {
  id: 'PROFILE_REFUND',
  description: 'Profile a refund'
};
export const PROVIDE_CREDIT = {
  id: 'PROVIDE_CREDIT',
  description: 'Provide a partial credit'
};
export const MAKE_DEAL = { id: 'MAKE_DEAL', description: 'Make a deal' };
export const SELL_CAR = { id: 'SELL_CAR', description: 'Sell a Car(New/Used)' };
export const FI_UPSELL_INVENTORY = {
  id: 'FI_UPSELL_INVENTORY',
  description: 'Add & Maintain F&I upsell inventory'
};
export const SELL_FI_PRODUCT = {
  id: 'SELL_FI_PRODUCT',
  description: 'Sell F&I product'
};
export const UNWIND_DEAL = { id: 'UNWIND_DEAL', description: 'Unwind a deal' };
export const ACCDRFX = { id: 'ACCDRFX', description: 'Access Drive Flex' };
