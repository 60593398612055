import { connect } from 'react-redux';
import { hideToast, showToast } from 'redux-store/general.reducers';
import Toasts from './toasts';

export default connect(
  // mapStateToProps
  ({ ui }) => ({
    toasts: ui.toasts
  }),
  { showToast, hideToast }
)(Toasts);
