import { ApiHandler2 as makeReq } from '@cdk-flex/components-api-handler';

const ACCOUNTING_API_ENDPOINT =
  process.env.REACT_APP_ACCOUNTING_API_ENDPOINT || 'ds-flex-accounting/v1';

const accountingApi = {
  glCoaShortList: (type, companyId) =>
    makeReq({
      url: `${ACCOUNTING_API_ENDPOINT}/gl-chart-of-accounts/${companyId}/shortList?type=${type}`,
      method: 'GET'
    }),
  getArCreditInfo: customerId =>
    makeReq({
      //
      url: `${ACCOUNTING_API_ENDPOINT}/ar-documents/customer-summary/${customerId}`,
      method: 'GET'
    }),
  getJournalNumbers: () =>
    makeReq({
      url: `${ACCOUNTING_API_ENDPOINT}/gl-journal-setup`,
      method: 'GET'
    }),
  getGlCoaData: params =>
    makeReq({
      url: `${ACCOUNTING_API_ENDPOINT}/gl-coa`,
      method: 'GET',
      params
    }),
  postDocument(data, interfaceCode) {
    return makeReq({
      method: 'POST',
      url: `${ACCOUNTING_API_ENDPOINT}/documents?interfaceCode=${interfaceCode}`,
      data
    });
  },
  getCashReportData: (companyId, beginDate, endDate) =>
    makeReq({
      url: `${ACCOUNTING_API_ENDPOINT}/cash-receipts/${companyId}/cash-reports?beginDate=${beginDate}&endDate=${endDate}`,
      method: 'GET'
    }),
  updateCashReceipt: (companyId, data) =>
    makeReq({
      url: `${ACCOUNTING_API_ENDPOINT}/cash-receipts/${companyId}`,
      method: 'POST',
      data
    })
};

export default accountingApi;
