import React, { Component, Fragment } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape
} from 'react-intl';
import { arrayOf, func, shape, string } from 'prop-types';
import { Print, NoPrint } from 'react-easy-print';
import { CircularProgress } from '@cdk-uip/react-circular-progress';
import { NotificationDialog } from '@cdk-flex/components-dialog';
import {
  Page,
  PageContent,
  PageHeader,
  PageHeaderAction,
  PageHeaderActions,
  PageHeaderPrevious,
  PageHeaderSubtitleText,
  PageHeaderTitle,
  PageSection
} from '@cdk-flex/components-page';
import {
  StockStatus,
  VehicleForm,
  vehicleAPI,
  UNLIMITED_ACCESS,
  LIMITED_ACCESS,
  READ_ONLY,
  INACTIVE_STATUSES,
  INVENTORY_ERROR_BANNER
} from '@cdk-flex/components-vehicles';
import { withErrorBanner, withErrorModal } from '@cdk-flex/components-error';
import { withPermissions } from '@cdk-flex/components-flex';
import { salesApi } from '../../api/resources';
import { logger } from 'services';
import { title } from 'utils';
import i18n from './i18nMessages';
import './style.less';

const messages = defineMessages({
  notAvailable: {
    id: 'FLEX.CORE.GET_DATA_RESULT.NOT_AVAILABLE',
    defaultMessage: 'N/A'
  }
});

const UNLIMITED_ROLE = 'CREATE_VEHICLE';
const LIMITED_ROLE = 'UPDATE_VEHICLE_COMMENTS';

class VehicleUpdateView extends Component {
  state = {
    saving: false,
    loading: true,
    saveDisabled: false,
    vehicle: {
      id: '',
      stockType: '',
      stockStatus: '',
      vin: '',
      stockNumber: '',
      year: '',
      make: {
        abbreviation: '',
        name: ''
      },
      model: {
        abbreviation: '',
        name: ''
      },
      modelNumber: '',
      modelType: '',
      body: '',
      odometer: '',
      exteriorColor: '',
      interiorColor: '',
      trim: '',
      engine: '',
      cylinders: '',
      drivetrain: '',
      transmission: '',
      manufacturer: {
        abbreviation: '',
        name: ''
      },
      grossVehicleWeight: '',
      keyNumber: '',
      inventoryDetails: {
        acquisitionType: '',
        lot: '',
        entryDate: '',
        inServiceDate: ''
      },
      options: [
        {
          code: '',
          category: '',
          description: '',
          cost: '',
          retailPrice: '',
          invoicePrice: ''
        }
      ],
      cost: {
        saleAccountNumber: '',
        inventoryAccountNumber: '',
        currentCost: '',
        invoiceNumber: '',
        invoiceDate: '',
        balance: ''
      },
      pricing: {
        baseMsrp: '',
        retailPrice: ''
      }
    },
    displayDeletePopup: false,
    vehicleInventoryStatusChanged: false,
    errorBanner: '',
    lots: []
  };

  getVehicleTitle = vehicle => {
    let vehicleTitle = '';
    if (vehicle) {
      if (vehicle.type) {
        vehicleTitle += `${vehicle.type} `;
      }
      if (vehicle.year) {
        vehicleTitle += `${vehicle.year} `;
      }
      if (vehicle.make) {
        vehicleTitle += `${vehicle.make.name} `;
      }
      if (vehicle.model) {
        vehicleTitle += `${vehicle.model.name} `;
      }
    }
    if (vehicleTitle === '') {
      vehicleTitle = this.props.intl.formatMessage(messages.notAvailable);
    }
    return vehicleTitle;
  };

  componentDidMount = () => {
    this.loadData();
  };

  loadData = async (setLoading = true) => {
    const urlComponents = window.location.pathname.split('/');
    const stockNumber = urlComponents[urlComponents.length - 1];
    title(
      this.props.intl.formatMessage(
        { ...i18n.updateVehicleTitle },
        { stockNumber }
      )
    );
    try {
      const response = await vehicleAPI.getInvVehicle(stockNumber);
      if (response.errors) {
        throw new Error(response.errors);
      }

      // preserve stock number incase of future edit when stockNumber and productionNumber are same.
      const vehicle = {
        ...response,
        oldStockNumber: stockNumber
      };

      this.setState({ vehicle });
    } catch (e) {
      this.props.showErrorModal({
        content: (
          <FormattedMessage
            id="FLEX.CORE.VEHICLE.ERROR.MSG.LOAD_INVENTORY_VEHICLE_ERROR"
            defaultMessage="Unable to load {stockNumber} vehicle. Please retry. If issue persists, contact support."
            values={{ stockNumber }}
          />
        ),
        onRetry: () => this.loadData(false),
        onCancel: () => this.props.history.push('/vehicles/inventory')
      });
    }
    this.setState({ loading: false });
  };

  onDelete = async () => {
    this.setState({ displayDeletePopup: false });
    if (this.state.vehicleInventoryStatusChanged) {
      await this.onSave(false);
    }
    try {
      const response = await vehicleAPI.deleteInvVehicle(
        this.state.vehicle.stockNumber
      );
      if (response.errors) {
        this.displayErrorBanner(response.errors.join('.'), this.onDelete);
      }
      this.props.history.push('/vehicles/inventory');
      logger(
        <FormattedMessage
          id="FLEX.CORE.VEHICLE.DELETE.MSG.DELETE_VEHICLE_SUCCESS"
          defaultMessage="Vehicle successfully deleted"
        />
      );
    } catch (error) {
      const err = await error.json();
      const errors = err.errors.join(', ');
      this.displayErrorBanner(errors, this.onDelete);
    }
  };

  saveButtonClicked = () => {
    const { vehicle } = this.state;
    const stockNumberChanged = vehicle.stockNumber !== vehicle.oldStockNumber;
    if (!stockNumberChanged) {
      this.onSave();
    } else {
      this.switchStockNumber(vehicle.stockNumber, vehicle.oldStockNumber);
    }
  };

  switchStockNumber = (newStockNumber, oldStockNumber) => {
    this.setState(
      {
        saveDisabled: true,
        saving: true,
        vehicle: { ...this.state.vehicle, stockNumber: oldStockNumber }
      },
      async () => {
        const saved = await this.onSave(false);
        if (saved) {
          try {
            const res = await vehicleAPI.changeStockNumber(
              newStockNumber,
              oldStockNumber
            );
            if (res.id === newStockNumber) {
              this.setState({ saveDisabled: false, saving: false });
              this.props.history.push('/vehicles/inventory');
            }
          } catch (errors) {
            this.setState({ saveDisabled: false, saving: false });
            this.displayErrorBanner(errors, this.saveButtonClicked);
          }
        }
      }
    );
  };

  onSave = async (exitOnSuccess = true) => {
    this.props.cancelAllErrorBanners();
    let wasSuccessful = false;
    if (!document.querySelector('.vehicle-form').checkValidity()) {
      this.setState({ saveDisabled: true });
    } else {
      this.setState({ saving: true });
      try {
        const { vehicle } = this.state;
        const response = await vehicleAPI.updateInvVehicle(vehicle);
        if (exitOnSuccess) this.props.history.push('/vehicles/inventory');
        if (response.errors) {
          this.setState({ saveDisabled: true, saving: false });
          this.displayErrorBanner(response.errors.join('.'), this.onSave);
        } else {
          wasSuccessful = true;
          salesApi.updateVehicleCost(vehicle.stockNumber);
        }
      } catch (error) {
        let errorMessages = error;
        if (error.errors || error.error) {
          errorMessages = error.errors ? error.errors.join('.') : error.error;
        }
        this.setState({
          saving: false,
          saveDisabled: false
        });
        this.displayErrorBanner(errorMessages, this.onSave);
      }
      this.setState({
        saving: false,
        saveDisabled: false
      });
    }
    return wasSuccessful;
  };

  getAccessibilityLimit = () => {
    const { permissions } = this.props;
    if (permissions.includes(UNLIMITED_ROLE)) {
      return UNLIMITED_ACCESS;
    } else if (permissions.includes(LIMITED_ROLE)) {
      return LIMITED_ACCESS;
    } else {
      return READ_ONLY;
    }
  };

  showSaveButton = errorBanner => this.setState({ errorBanner });

  componentDidUpdate = prevProps => {
    if (prevProps !== this.props) {
      this.setState(prevState => ({
        errorBanner:
          (prevState.vehicle.vin.length !== 17 ||
            INACTIVE_STATUSES.includes(prevState.vehicle.stockStatus)) &&
          ''
      }));
    }
  };

  displayErrorBanner = (errorContent, retryFunction) => {
    this.props.showErrorBanner({
      content: errorContent,
      onRetryBanner: retryFunction
    });
  };

  render() {
    const { vehicle } = this.state;
    const name = this.getVehicleTitle(vehicle);
    const limitAccessibility = this.getAccessibilityLimit();

    return (
      <Print single name="vehicle-record">
        <Page className="vehicle-update">
          {this.state.loading ? (
            <CircularProgress />
          ) : (
            <Fragment>
              <PageHeader>
                <PageHeaderPrevious
                  handleNav={() =>
                    this.props.history.push('/vehicles/inventory')
                  }
                >
                  <FormattedMessage
                    id="FLEX.CORE.VEHICLE.UPDATE.LINK.BACK_TO_VEHICLE_LIST"
                    defaultMessage="Back to Vehicle Inventory"
                  />
                </PageHeaderPrevious>
                <PageHeaderTitle className="vehicle-title">
                  {name}
                </PageHeaderTitle>
                <div className="vehicle-title-status">
                  {vehicle.stockNumber ? (
                    <StockStatus
                      stockStatus={vehicle.stockStatus}
                      disabled={limitAccessibility !== UNLIMITED_ACCESS}
                      onChange={stockStatus =>
                        this.setState({
                          vehicle: { ...vehicle, stockStatus },
                          vehicleInventoryStatusChanged: true
                        })
                      }
                    />
                  ) : (
                    ''
                  )}
                </div>
                <PageHeaderSubtitleText>
                  <FormattedMessage
                    id="FLEX.CORE.VEHICLE.UPDATE.VIN"
                    defaultMessage="VIN: "
                  />
                  {vehicle.vin || ''}{' '}
                  <FormattedMessage
                    id="FLEX.CORE.VEHICLE.UPDATE.STOCK_NUMBER"
                    defaultMessage="Stock # "
                  />
                  {vehicle.stockNumber || ''}
                </PageHeaderSubtitleText>
                <NoPrint>
                  {limitAccessibility !== READ_ONLY && (
                    <PageHeaderActions>
                      {this.state.vehicle.stockStatus === 'GONE' &&
                        this.props.permissions.includes('DELETE_VEHICLE') && (
                          <PageHeaderAction
                            onClick={() =>
                              this.setState({
                                displayDeletePopup: true
                              })
                            }
                          >
                            <FormattedMessage
                              id="VehicleForm.delete"
                              defaultMessage="Delete"
                            />
                          </PageHeaderAction>
                        )}
                      <PageHeaderAction
                        primary
                        raised
                        disabled={
                          this.state.saveDisabled ||
                          this.state.saving ||
                          this.state.errorBanner === INVENTORY_ERROR_BANNER
                        }
                        onClick={this.saveButtonClicked}
                        data-testid="vehicle-update-save-button"
                      >
                        {this.state.saving ? (
                          <FormattedMessage
                            id="FLEX.CORE.VEHICLE.UPDATE.BTN.SAVING"
                            defaultMessage="Saving..."
                          />
                        ) : (
                          <FormattedMessage
                            id="FLEX.CORE.VEHICLE.UPDATE.BTN.SAVE"
                            defaultMessage="Save"
                          />
                        )}
                      </PageHeaderAction>
                    </PageHeaderActions>
                  )}
                </NoPrint>
              </PageHeader>
              <PageContent>
                <PageSection id="grid-list">
                  {this.state.displayDeletePopup && (
                    <NotificationDialog
                      className="delete-notification"
                      onCancel={() =>
                        this.setState({ displayDeletePopup: false })
                      }
                      onOkay={this.onDelete}
                      open={this.state.displayDeletePopup}
                      title={
                        <FormattedMessage
                          id="FLEX.CORE.VEHICLE.DELETE.TITLE"
                          defaultMessage="Delete this vehicle from inventory?"
                        />
                      }
                      cancelButtonText={
                        <FormattedMessage
                          id="FLEX.CORE.VEHICLE.DELETE.CANCEL"
                          defaultMessage="Cancel"
                        />
                      }
                      okayButtonText={
                        <FormattedMessage
                          id="FLEX.CORE.VEHICLE.DELETE.DELETE"
                          defaultMessage="Delete"
                        />
                      }
                    />
                  )}
                  <VehicleForm
                    isUpdate
                    isFull
                    vehicle={this.state.vehicle}
                    onChange={val => this.setState({ vehicle: val.vehicle })}
                    disableSave={val => this.setState({ saveDisabled: val })}
                    limitAccessibility={limitAccessibility}
                    showSaveButton={this.showSaveButton}
                  />
                </PageSection>
              </PageContent>
            </Fragment>
          )}
        </Page>
      </Print>
    );
  }
}

VehicleUpdateView.propTypes = {
  intl: intlShape.isRequired,
  /** Callback function to display error banner */
  showErrorBanner: func,
  /** Callback function to remove all error banners from the page */
  cancelAllErrorBanners: func,
  showErrorModal: func,
  permissions: arrayOf(string),
  history: shape({
    push: func
  })
};

VehicleUpdateView.defaultProps = {
  showErrorBanner: () => {},
  cancelAllErrorBanners: () => {},
  showErrorModal: () => {},
  permissions: [],
  history: {
    push: () => {}
  }
};

export default withErrorBanner(
  withErrorModal(withPermissions(injectIntl(VehicleUpdateView)))
);
