import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'FLEX.CORE.ONEPAY.PERMISSIONS_MAPPINGS.TITLE',
    defaultMessage: 'OnePay Payment Mappings'
  },
  getDepartmentsFail: {
    id: 'FLEX.CORE.ONEPAY.GET_DEPARTMENTS.REQUEST.ERROR.MESSAGE',
    defaultMessage: 'Unable to load department details. Please retry'
  }
});
