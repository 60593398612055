import i18nMessages from '../i18nMessages';

export const NEW = 'NEW';
export const USED = 'USED';
export const RENTAL = 'RENTAL';
export const MISC = 'MISCELLANEOUS';
export const MANUFACTURER = 'MANUFACTURER';
export const IN_STOCK = 'IN_STOCK';

export const CREATE_VEHICLE = 'CREATE_VEHICLE';
export const UPDATE_COST = 'UPDATE_COST';
export const READ_COST = 'READ_COST';

export const ERROR_TYPES = {
  formValidationError: 'FORM_VALIDATION_ERROR',
  apiError: 'API_ERROR'
};

export const NEW_JOURNAL = '70';
export const USED_JOURNAL = '71';

export const API_DATE_FORMAT = 'YYYY-MM-DD';

export const LOADING = 'LOADING';
export const SAVING = 'SAVING';

export const ERROR_STRING_MAPPINGS = {
  vin: i18nMessages.vin,
  odometer: i18nMessages.odometer,
  year: i18nMessages.year,
  'make.abbreviation': i18nMessages.make,
  'model.abbreviation': i18nMessages.model,
  exteriorColor: i18nMessages.exteriorColor,
  stockNumber: i18nMessages.stockNumber,
  'cost.inventoryAccountNumber': i18nMessages.inventoryAccountNumber,
  'inventoryDetails.acquisitionType': i18nMessages.acquisitionType,
  'inventoryDetails.entryDate': i18nMessages.entryDate,
  advertising: i18nMessages.advertising,
  holdback: i18nMessages.holdback,
  hardPack: i18nMessages.hardPack,
  inventoryCost: i18nMessages.initialInventoryCost,
  invoiceAmount: i18nMessages.totalInvoiceAmount,
  dealerCost: i18nMessages.acquisitionCost,
  baseVehicleCost: i18nMessages.actualCashValue,
  pack: i18nMessages.hardPack,
  journalId: i18nMessages.journal,
  accountingDate: i18nMessages.accountingDate
};
