export default {
  // Toast
  SHOW_TOAST: 'SHOW_TOAST',
  HIDE_TOAST: 'HIDE_TOAST',

  // User
  LOAD_USER: 'LOAD_USER',
  AUTH_ERROR: 'AUTH_ERROR',
  AUTH_SILENT_RENEW_ERROR: 'AUTH_SILENT_RENEW_ERROR',
  AUTH_SESSION_EXPIRED: 'AUTH_SESSION_EXPIRED',
  AUTHENTICATE: 'AUTHENTICATE',

  // Identity
  LOAD_IDENTITY: 'LOAD_IDENTITY',
  LOAD_APPLICATIONS: 'LOAD_APPLICATIONS',
  LOAD_PERMISSIONS: 'LOAD_PERMISSIONS',

  // i18n
  I18N_LANGUAGE: 'I18N_LANGUAGE',
  I18N_LOCALE: 'I18N_LOCALE'
};
