import { ApiHandler2 as makeReq } from '@cdk-flex/components-api-handler';

const SALES_SETUPS_BASE_URL =
  process.env.REACT_APP_SALES_SETUPS_ENDPOINT || 'ds-flex-sales-setups/v1';

const financeFeeSetupEndpoint = `${SALES_SETUPS_BASE_URL}/setup`;

const salesSetupsApi = {
  getPrepaidFinanceFeeFlag: () =>
    makeReq({
      url: `${financeFeeSetupEndpoint}/prepaid-fee-setup`,
      method: 'GET'
    }),
  getVsiFeeFlag: () =>
    makeReq({
      url: `${financeFeeSetupEndpoint}/vsi-fee-setup`,
      method: 'GET'
    })
};

export default salesSetupsApi;
