import React from 'react';
import { Page, PageContent } from '@cdk-flex/components-page';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import { AppLinkCard } from 'components/app-link-card';
import { HomePageHeader } from './page';
import { dashboardCards } from './dashboardCards';
import { withPermissions } from '@cdk-flex/components-flex';
import { withFlags } from '@cdk-flex/components-launch-darkly';
import './style.less';

const filterCards = (permissions, flags) => {
  return dashboardCards(flags)
    .filter(dashboardCard => permissions.includes(dashboardCard.permissionId))
    .map((dashboardCard, index) => (
      <LayoutGridCell key={index} span={4} className="dashboard-card">
        <AppLinkCard
          text={dashboardCard.cardText}
          avatarText={dashboardCard.avatarText}
          link={dashboardCard.link}
          quicklinks={dashboardCard.quickLinks}
          permissions={permissions}
        />
      </LayoutGridCell>
    ));
};

const Home = props => (
  <Page>
    <HomePageHeader />
    <PageContent className="dashboard">
      <LayoutGrid className="dashboard-cards">
        {filterCards(props.permissions, props.flags)}
      </LayoutGrid>
    </PageContent>
  </Page>
);

export default withPermissions(withFlags(Home));
