import { ApiHandler2 as makeReq } from '@cdk-flex/components-api-handler';

const FIN_INST_BASE_URL =
  process.env.REACT_APP_LENDERS_API_ENDPOINT || 'ds-flex-lender/v1';

const endpoint = `${FIN_INST_BASE_URL}/lenders`;

const api = {
  getFinancialInstitutions: () =>
    makeReq({
      url: `${endpoint}`,
      method: 'GET'
    }),

  getFinancialInstitution: id =>
    makeReq({
      method: 'GET',
      url: `${endpoint}/${id}`
    }),

  addFinancialInstitution: data =>
    makeReq({
      method: 'POST',
      url: `${endpoint}/create`,
      data
    }),

  updateFinancialInstitution: data =>
    makeReq({
      method: 'POST',
      url: `${endpoint}/update/${data.id}`,
      data
    })
};

export default api;
