import { ApiHandler2 as makeReq } from '@cdk-flex/components-api-handler';

const SERVICE_BASE_URL = process.env.REACT_APP_SERVICE_API_ENDPOINT;

const serviceSkillCodeApi = {
  fetchDefaultSkillCodes: () =>
    makeReq({
      url: `${SERVICE_BASE_URL}/skillcode-setup`,
      method: 'GET'
    })
};

export default serviceSkillCodeApi;
