import React, { Component } from 'react';
import { object } from 'prop-types';

/**
 * HOC for wrapping an element that is interested in updates to spied
 * components in the current scrollSpy context. This HOC function takes
 * a function parameter that maps the current scrolled element ID to
 * the wrapped components props (similar to Redux mapStateToProps).
 *
 * @param {function} mapCurrentToProps
 */
function scrollSpySubscriber(mapCurrentToProps) {
  return function(WrappedComponent) {
    return class extends Component {
      constructor(props) {
        super(props);
        this.state = { current: null };
      }

      componentDidMount() {
        const { subscribe } = this.context.scrollSpy;

        subscribe(this.handler);
      }

      componentWillUnmount() {
        const { unsubscribe } = this.context.scrollSpy;

        unsubscribe(this.handler);
      }

      handler = current => {
        this.setState({ current });
      };

      render() {
        const mappedProps = mapCurrentToProps(this.state.current);

        return <WrappedComponent {...mappedProps} {...this.props} />;
      }

      static contextTypes = {
        scrollSpy: object
      };
    };
  };
}

export default scrollSpySubscriber;
