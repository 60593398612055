import {
  ApiHandler,
  ApiHandlerRaw,
  ApiHandler2 as makeReq
} from '@cdk-flex/components-api-handler';

const BASE = 'ds-flex-settings/v1';
const SALES_SETUPS_BASE_URL = 'ds-flex-sales-setups/v1';

const addEnterpriseIdToHeader = addEnterpriseId => {
  const headers = new Headers();
  headers.append('x-enterprise-id', addEnterpriseId);
  return headers;
};

export default {
  updateVehicleSettings: data =>
    ApiHandler({ url: `${BASE}/settings/vehicle`, method: 'POST', data }),
  getVehicleCommissionPack() {
    return ApiHandler({
      url: `${SALES_SETUPS_BASE_URL}/setup/get-vehicle-commission-pack`,
      method: 'GET'
    });
  },
  setVehicleCommissionPack(data) {
    return ApiHandler({
      url: `${SALES_SETUPS_BASE_URL}/setup/set-vehicle-commission-pack`,
      method: 'POST',
      data
    });
  },
  fetchLotsByStoreId(enterpriseId) {
    const headers = addEnterpriseIdToHeader(enterpriseId);
    return makeReq({
      url: `${BASE}/settings/lot-configuration`,
      method: 'GET',
      headers
    });
  },
  createLotForStore(enterpriseId, data) {
    const headers = addEnterpriseIdToHeader(enterpriseId);
    return makeReq({
      url: `${BASE}/settings/lot-configuration`,
      method: 'POST',
      data,
      headers
    });
  },
  updateLotForStore(enterpriseId, lotId, data) {
    const headers = addEnterpriseIdToHeader(enterpriseId);
    return makeReq({
      url: `${BASE}/settings/lot-configuration/${lotId}`,
      method: 'PUT',
      data,
      headers
    });
  },
  deleteLot(enterpriseId, lotId) {
    const headers = addEnterpriseIdToHeader(enterpriseId);
    return ApiHandlerRaw({
      url: `${BASE}/settings/lot-configuration/${lotId}`,
      method: 'DELETE',
      headers
    });
  }
};
