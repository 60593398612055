import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { CircularProgress } from '@cdk-uip/react-circular-progress';
import { withFlags } from '@cdk-flex/components-launch-darkly';
import { NotFound } from '@cdk-flex/components-not-found';
import { PageSpinner } from '@cdk-uip/react-page-spinner';
import useEnterpriseId from 'utils/custom-hooks/useEnterpriseId';
import { ProtectedRoute } from '@cdk-flex/components-flex';
import { OemSettings } from '@cdk-flex/oemi-settings';
import { general } from 'api/resources';
import { Home } from 'views/home';
import { OEMFiles } from '@cdk-flex/oemi-responses';
import VehicleUpdateView from 'views/vehicles/VehicleUpdateView';
import { VehicleDetails } from './views/vehicles-rds';
import { OnePayService } from './services';
import onePayConstants from './constants/onePay';
import PaymentMappingWrapper from './views/onepay-payment-mapping/PaymentMappingManagementLauncher';

const Loading = () => <CircularProgress />;

const CustomerListView = Loadable({
  loader: () => import('views/customers/CustomerListView'),
  loading: Loading
});

const CustomerUpdateView = Loadable({
  loader: () => import('views/customers/CustomerUpdateView'),
  loading: Loading
});

const AdministrationDashboardView = Loadable({
  loader: () =>
    import('views/administration-dashboard/AdministrationDashboardView'),
  loading: Loading
});

const EmployeeUpdateView = Loadable({
  loader: () => import('views/employees/EmployeeUpdateView'),
  loading: Loading
});

const InstitutionsListView = Loadable({
  loader: () => import('views/institutions/InstitutionsListView'),
  loading: Loading
});

const InstitutionUpdateView = Loadable({
  loader: () => import('views/institutions/InstitutionUpdateView'),
  loading: Loading
});

const VehicleListView = Loadable({
  loader: () => import('views/vehicles/VehicleListView'),
  loading: Loading
});

const EmployeeListView = Loadable({
  loader: () => import('views/employees/EmployeeListView'),
  loading: Loading
});

const VendorListView = Loadable({
  loader: () => import('views/vendors/VendorListView'),
  loading: Loading
});

const VendorUpdateView = Loadable({
  loader: () => import('views/vendors/VendorUpdateView'),
  loading: Loading
});

const CashieringListView = Loadable({
  loader: () => import('views/cashiering/CashieringListView'),
  loading: Loading
});

const CashReceiptForm = Loadable({
  loader: () => import('views/cashiering/CashReceiptForm'),
  loading: Loading
});

const CashieringReport = Loadable({
  loader: () => import('views/cashiering/CashieringReport'),
  loading: Loading
});

const InventorySettingsView = Loadable({
  loader: () => import('views/settings/InventorySettings'),
  loading: Loading
});

const GCM = Loadable({
  loader: () => import('views/content-management/ContentSearch'),
  loading: Loading
});

const PrintRegistryView = Loadable({
  loader: () => import('views/print-registry/PrintRegistryView'),
  loading: Loading
});

const ServiceSetups = Loadable({
  loader: () => import('views/service-pricing/ServiceSetups'),
  loading: Loading
});

const SpecialPromotions = Loadable({
  loader: () =>
    import('views/service-pricing/specialpromotions/SpecialPromotions'),
  loading: Loading
});
const OperationsDashboard = Loadable({
  loader: () => import('views/service-pricing/operations/OperationsDashboard'),
  loading: Loading
});

const OperationSettings = Loadable({
  loader: () =>
    import('views/service-pricing/common/operation-settings/OperationSettings'),
  loading: Loading
});

const onePayPaymentMappingsView = Loadable({
  loader: () => import('views/onepay-payment-mapping/PaymentMappingsListView'),
  loading: Loading
});

const FormsManagementView = Loadable({
  loader: () => import('views/eforms-forms-management/FormsManagementView'),
  loading: Loading
});

// ***Removing feature from prod***
// const CommonSetups = Loadable({
//   loader: () => import('views/common-settings/CommonSetups'),
//   loading: Loading
// });

const cashieringAllowFn = userPermissions => {
  const genericPermissions = [
    'READ_CASHIERING_GENERIC',
    'CREATE_CASHIERING_GENERIC'
  ];
  const otherPermissions = ['READ_CASHIERING_PARTS', 'READ_CASHIERING_SERVICE'];

  return (
    genericPermissions.some(p => userPermissions.includes(p)) ||
    otherPermissions.every(p => userPermissions.includes(p))
  );
};

const Routes = ({ flags }) => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route
        exact
        path="/serviceconnect"
        render={() => {
          general.serviceConnect().then(url => window.location.replace(url));
          return <PageSpinner show />;
        }}
      />
      <Route
        path="/administration-dashboard"
        component={AdministrationDashboardView}
      />
      <ProtectedRoute
        path="/customers"
        component={CustomerListView}
        allow={['READ_CUSTOMER']}
      />
      <ProtectedRoute
        path="/customer/:id"
        component={CustomerUpdateView}
        allowFn={userPermissions => {
          const customerPermissions = [
            'READ_CUSTOMER',
            'UPDATE_CUSTOMER_COMMENTS',
            'CREATE_CUSTOMER'
          ];
          return customerPermissions.some(p => userPermissions.includes(p));
        }}
      />
      <ProtectedRoute
        path="/employees"
        component={EmployeeListView}
        allow={['READ_EMPLOYEE']}
      />
      <ProtectedRoute
        path="/employee/:id"
        component={EmployeeUpdateView}
        allow={['READ_EMPLOYEE', 'CREATE_EMPLOYEE']}
      />
      <ProtectedRoute
        path="/lenders"
        component={InstitutionsListView}
        allow={['READ_FINANCIAL_INSTITUTION']}
      />
      <ProtectedRoute
        path="/lender/:id"
        component={InstitutionUpdateView}
        allow={['READ_FINANCIAL_INSTITUTION', 'CREATE_FINANCIAL_INSTITUTION']}
      />
      <ProtectedRoute
        exact
        path="/vehicles"
        allow={['READ_VEHICLE']}
        render={VehicleListView}
      />
      <ProtectedRoute
        path="/vehicles/inventory"
        allow={['READ_VEHICLE']}
        component={VehicleListView}
      />
      <ProtectedRoute
        path="/vehicles/oem"
        component={OEMFiles}
        allow={['READ_VEHICLE']}
      />
      {!(flags && flags.coreVehicleFormRds) ? (
        <ProtectedRoute
          path="/vehicle/:id"
          component={VehicleUpdateView}
          allowFn={userPermissions => {
            const allowablePermissions = [
              'READ_VEHICLE',
              'CREATE_VEHICLE',
              'UPDATE_VEHICLE_COMMENTS'
            ];
            return allowablePermissions.some(p => userPermissions.includes(p));
          }}
        />
      ) : (
        <ProtectedRoute
          path="/vehicle/:id?"
          component={VehicleDetails}
          allowFn={userPermissions => {
            const allowablePermissions = [
              'READ_VEHICLE',
              'CREATE_VEHICLE',
              'UPDATE_VEHICLE_COMMENTS'
            ];
            return allowablePermissions.some(p => userPermissions.includes(p));
          }}
        />
      )}
      <ProtectedRoute
        path="/vehicle-inventory-settings"
        component={InventorySettingsView}
        allowFn={userPermissions => {
          const invSettingsPermissions = [
            'UPDATE_SYSTEM_SETTINGS',
            'SYSADMIN_FLEX'
          ];
          return invSettingsPermissions.some(p => userPermissions.includes(p));
        }}
      />
      <ProtectedRoute
        path="/vendors"
        component={VendorListView}
        allow={['READ_VENDOR']}
      />
      <ProtectedRoute
        path="/vendor/:id"
        component={VendorUpdateView}
        allow={['READ_VENDOR', 'CREATE_VENDOR']}
      />
      <ProtectedRoute
        path="/oem-settings"
        component={OemSettings}
        allowFn={userPermisssions =>
          ['UPDATE_SYSTEM_SETTINGS', 'SETUP_OEM_INTEGRATION'].some(p =>
            userPermisssions.includes(p)
          )
        }
      />
      <ProtectedRoute
        path="/oem-files"
        component={OEMFiles}
        allow={['VIEW_FILES_OEM_INTEGRATION']}
      />
      <Route path="/cashiering/payment" component={CashReceiptForm} />
      <ProtectedRoute
        path="/cashiering/report"
        component={CashieringReport}
        allowFn={userPermissions => cashieringAllowFn(userPermissions)}
      />
      <ProtectedRoute
        path="/cashiering"
        component={CashieringListView}
        allowFn={userPermissions => cashieringAllowFn(userPermissions)}
      />

      <Route
        exact
        path="/onepay-admin"
        component={() => {
          new OnePayService()
            .serviceConnect(onePayConstants.admin)
            .then(url => window.location.replace(url));
          return <PageSpinner show />;
        }}
      />

      <Route
        exact
        path="/onepay-payment-mappings"
        component={onePayPaymentMappingsView}
      />

      <Route
        exact
        path={`/onepay-payment-mappings/:department`}
        component={PaymentMappingWrapper}
      />

      <Route path="/gcm" component={GCM} />
      <Route path="/print-registry" component={PrintRegistryView} />
      <Route
        path="/service-pricing/operations"
        exact
        component={OperationsDashboard}
      />
      <Route path="/service-pricing" component={ServiceSetups} exact />
      <Route
        path="/service-pricing/operations/operation-settings/:servicePackageId"
        component={OperationSettings}
      />
      <Route
        path="/service-pricing/specialpromotions"
        exact
        component={SpecialPromotions}
      />
      {flags && flags.eformsFormsManagement && (
        <Route
          exact
          path="/eforms-forms-management"
          component={FormsManagementView}
        />
      )}
      {/* ***Removing feature from prod*** */}
      {/* <Route path="/common-settings" component={CommonSetups} exact /> */}
      <Route render={NotFound} />
    </Switch>
  );
};

export default withFlags(Routes);
