import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Page,
  PageContent,
  PageHeader,
  PageHeaderTitle
} from '@cdk-flex/components-page';
import { withErrorBanner } from '@cdk-flex/components-error';
import { PageSpinner } from '@cdk-uip/react-page-spinner';
import { PaymentMappingsManagement } from '@cdkglobal/payment-mapping-component';
import { OnePayService } from '../../services';
import onePayConstants from '../../constants/onePay';
import i18n from './i18nMessages';
import BackToAdmin from '../../components/back-to-admin';

const PaymentMappingManagementLauncher = props => {
  const { showErrorBanner, history } = props;

  const [flexInfo, setFlexInfo] = useState({});
  const [loading, setLoading] = useState(true);

  const getFlexInfo = async () => {
    try {
      return await new OnePayService()
        .serviceConnect(onePayConstants.paymentMappings)
        .then(res => setFlexInfo(res));
    } catch (e) {
      showErrorBanner({
        content: <FormattedMessage {...i18n.getDepartmentsFail} />,
        onRetryBanner: () => getFlexInfo(),
        onCancelBanner: () => setLoading(false)
      });
    }
  };

  useEffect(() => {
    getFlexInfo().then(() => setLoading(false));
  }, []);

  return (
    <Page className="payment-mapping-wrapper">
      <PageHeader>
        <PageHeaderTitle>
          <BackToAdmin history={history} />
          <FormattedMessage {...i18n.title} />
        </PageHeaderTitle>
      </PageHeader>
      <PageContent>
        {loading && <PageSpinner show />}
        {!loading && <PaymentMappingsManagement flexData={flexInfo} isFlex />}
      </PageContent>
    </Page>
  );
};

export default injectIntl(withErrorBanner(PaymentMappingManagementLauncher));
