import types from './types';

const DEFAULT_STATE = {
  toasts: []
};

export const hideToast = toast => ({
  type: types.HIDE_TOAST,
  toast
});

export const showToast = text => dispatch => {
  const action = {
    type: types.SHOW_TOAST,
    toast: {
      timestamp: Date.now(),
      text
    }
  };

  dispatch(action);
  /* Sets how long toast displays for */
  const toastTimeOut = 3500;
  setTimeout(() => dispatch(hideToast(action.toast)), toastTimeOut);
};

export const uiReducers = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.HIDE_TOAST:
      return {
        ...state,
        toasts: state.toasts.filter(t => t.timestamp !== action.toast.timestamp)
      };
    case types.SHOW_TOAST:
      return {
        ...state,
        toasts: [...state.toasts, action.toast]
      };
    default:
      return state;
  }
};
