import { getIdFromSession } from './onePayServiceHelper';
import { employees, onePayApi } from '../../api/resources';
import onePayConstants from '../../constants/onePay';

export class OnePayService {
  async serviceConnect(path) {
    let connection;
    switch (path) {
      case onePayConstants.admin:
        connection = this.adminServiceConnect();
        break;
      case onePayConstants.payments:
        connection = this.paymentsServiceConnect();
        break;
      case onePayConstants.paymentMappings:
        connection = this.paymentMappingConnect();
        break;
      default:
        connection = { error: `No path matching '${path}' found` };
    }

    if (connection.error) {
      return new Error(connection.error);
    }

    return connection;
  }

  async adminServiceConnect() {
    try {
      const storeId = getIdFromSession(onePayConstants.storeIdKey);
      const employeeId = getIdFromSession(onePayConstants.employeeIdKey);
      const userId = await employees.getEmployee(employeeId);

      return await onePayApi.getAdminURL(storeId, userId.iamUser.id);
    } catch (err) {
      throw new Error(err.message);
    }
  }

  async paymentMappingConnect() {
    try {
      const { pathname } = window.location;
      const department = pathname.substring(pathname.lastIndexOf('/') + 1);
      const storeId = getIdFromSession(onePayConstants.storeIdKey);
      const response = await onePayApi.getGlobalEntityId(storeId);
      const globalEntityId = response.globalEntityId;

      return {
        globalEntityId: globalEntityId,
        account: department
      };
    } catch (err) {
      throw new Error(err.message);
    }
  }

  async paymentsServiceConnect() {
    return 'Not yet implemented';
  }
}
