const getIdFromSession = key => {
  const id = window.sessionStorage.getItem(`${key}`);

  if (id === null || id === undefined) {
    return new Error(`No ${key} is set.`);
  }

  return id.replace(/"/g, '');
};

module.exports = { getIdFromSession };
