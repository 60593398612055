import { ApiHandler2 as makeReq } from '@cdk-flex/components-api-handler';

const CUSTOMER_API_ENDPOINT =
  process.env.REACT_APP_CUSTOMER_API_ENDPOINT ||
  'ds-flex-customer/v1/customers';

const customerApi = {
  getCustomerById: id =>
    makeReq({
      url: `${CUSTOMER_API_ENDPOINT}/${id}`,
      method: 'GET'
    }),
  updateCustomer: (data, customerId) =>
    makeReq({
      url: `${CUSTOMER_API_ENDPOINT}/update/${customerId}`,
      method: 'POST',
      data
    })
};

export default customerApi;
