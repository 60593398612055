import { loadGenericMessages } from '@cdk-flex/oemi-generic-components';
import { loadOemSettingsMessages } from '@cdk-flex/oemi-settings';

const mergeFlexTranslations = async locale => {
  const appTranslations = await import(`./${locale}.json`);

  const packageTranslations = await Promise.all([
    import(`@cdk-flex/components-address-section/src/i18n/${locale}.json`),
    import(`@cdk-flex/components-add-resource/src/i18n/${locale}.json`),
    import(`@cdk-flex/components-coa-autocomplete/src/i18n/${locale}.json`),
    import(`@cdk-flex/components-employees/src/i18n/${locale}.json`),
    import(`@cdk-flex/components-flex/src/i18n/${locale}.json`),
    import(`@cdk-flex/components-makes-models/src/i18n/${locale}.json`),
    import(`@cdk-flex/components-not-found/src/i18n/${locale}.json`),
    import(`@cdk-flex/oemi-settings/src/i18n/${locale}.json`),
    import(`@cdk-flex/components-vin-decode/src/i18n/${locale}.json`),
    import(`@cdk-flex/components-vendors/src/i18n/${locale}.json`),
    import(`@cdk-flex/components-payments/src/i18n/${locale}.json`),
    import(`@cdk-flex/components-grid/src/i18n/${locale}.json`),
    import(`@cdk-flex/components-dialog/src/i18n/${locale}.json`),
    import(`@cdk-flex/components-customers/src/i18n/${locale}.json`),
    import(`@cdk-flex/components-vehicles/src/i18n/${locale}.json`),
    import(`@cdk-flex/components-error/src/i18n/${locale}.json`),
    import(`@cdk-flex/components-duplicates-sidebar/src/i18n/${locale}.json`),
    import(`@cdk-flex/oemi-responses/src/i18n/${locale}.json`),
    import(`@cdk-flex/components-persistent-search/src/i18n/${locale}.json`),
    import(`@cdk-flex/components-posting-lines/src/i18n/${locale}.json`),
    import(`@cdk-flex/components-utils/src/i18n/${locale}.json`),
    import(`@cdk-flex/components-country-select/src/i18n/${locale}.json`),
    import(`@cdk-flex/components-state-select/src/i18n/${locale}.json`)
  ]);

  return {
    ...packageTranslations.reduce((a, c) => ({ ...a, ...c }), {}),
    ...appTranslations,
    ...loadOemSettingsMessages(locale),
    ...loadGenericMessages(locale)
  };
};

export const loadLocaleData = ({ primary }) => {
  // Map of locales => data
  const data = {
    en: () => import('react-intl/locale-data/en'),
    fr: () => import('react-intl/locale-data/fr')
  };
  return data[primary]();
};

export const loadMessages = ({ primary, region }) => {
  const data = {
    'en-US': () => mergeFlexTranslations('en-US'),
    'fr-CA': () => mergeFlexTranslations('fr-CA')
  };

  return (data[`${primary}-${region}`] || data[primary])();
};
