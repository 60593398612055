import { setTitle } from '@cdk-flex/components-utils';

export * from './helpers.util';
export { host } from './host.util';
export { currency } from './currency.util';
export { currencyToNum } from './currency.util';
export { capitalize } from './capitalize.util';
export { toDash } from './toDash.util';
export { sortBy } from './sortBy.util';
export { filterBy } from './filterBy.util';
export { trim } from './trim.util';
export { toInitial } from './toInitial.util';
export { mergeBy } from './mergeBy.util';
export { has } from './has.util';
export { niceDate } from './niceDate.util';
export { parseParams } from './parseParams.util';
export { deepObjVal } from './deepObjVal.util';
export { isEmpty } from './isEmpty.util';
export { allPermissions } from './permissions.util';
export { formatTime } from './formatTime';
export { formatPhone } from './formatPhone';
export { toApiDateFormat, toApiTimeFormat } from './dateTimeFormat';
export { addEventsListener, removeEventsListener } from './domUtils';
export {
  getErrorMessage,
  getExceptionTextMessage
} from './getErrorMessage.utils';
export * from './vehicles.util';

export const title = setTitle('Core');
export { amplitudeStringMapping } from './constants';
