import { ApiHandler as makeReq } from '@cdk-flex/components-api-handler';
const SERVICE_BASE_URL = process.env.REACT_APP_SERVICE_API_ENDPOINT;

const servicePricingPromotionsApi = {
  getPromotions: () => {
    return makeReq({
      url: `${SERVICE_BASE_URL}/dealership-customizations/promotions`,
      method: 'GET',
      params: {
        includeExpired: true
      }
    });
  },
  getMakes(franchiseName) {
    return makeReq({
      url: `${SERVICE_BASE_URL}/dealership-setup/franchises`,
      method: 'GET',
      params: {
        franchiseName: franchiseName
      }
    });
  },
  getServicePackage: (searchText, makeId) => {
    return makeReq({
      url: `${SERVICE_BASE_URL}/pricing/packages/makes/${makeId}/search`,
      method: 'GET',
      params: {
        text: searchText
      }
    });
  },
  createPromotion: promotionData => {
    return makeReq({
      url: `${SERVICE_BASE_URL}/dealership-customizations/promotions`,
      method: 'POST',
      data: promotionData
    });
  },
  updatePromotion: (promotionId, promotionData) => {
    return makeReq({
      url: `${SERVICE_BASE_URL}/dealership-customizations/promotions/${promotionId}`,
      method: 'POST',
      data: promotionData
    });
  },
  deletePromotion: promotionId => {
    return makeReq({
      url: `${SERVICE_BASE_URL}/dealership-customizations/promotions/${promotionId}`,
      method: 'DELETE'
    });
  }
};

export default servicePricingPromotionsApi;
