import React from 'react';
import { FormattedMessage } from 'react-intl';
import { serviceCard } from "./serviceCard";

const andFn = (user, permissions) => permissions.every(p => user.includes(p));
const orFn = (user, permissions) => permissions.some(p => user.includes(p));

const getDashboardCards = (flags) => [
  {
    cardText: (
      <FormattedMessage
        id="FLEX.CORE.DASHBOARD.ACCOUNTING.CARDTEXT.ACCOUNTING"
        defaultMessage="Accounting"
      />
    ),
    avatarText: (
      <FormattedMessage
        id="FLEX.CORE.DASHBOARD.ACCOUNTING.AVATAR-TEXT"
        defaultMessage="Ac"
      />
    ),
    link: 'accounting',
    permissionId: 'ACCOUNTING_FLEX',
    quickLinks: [
      {
        id: 'Accounts Payable',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.ACCOUNTING.LINKNAME.ACCOUNTS-PAYABLE"
            defaultMessage="Accounts Payable"
          />
        ),
        link: 'accounting/accounts-payable/ap-invoice',
        permissionFn: up =>
          andFn(up, [
            'READ_ACCOUNTS_PAYABLE',
            'VOID_ACCOUNTS_PAYABLE',
            'CREATE_ACCOUNTS_PAYABLE'
          ])
      },
      {
        id: 'Accounts Receivable',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.ACCOUNTING.LINKNAME.ACCOUNTS-RECEIVABLE"
            defaultMessage="Accounts Receivable"
          />
        ),
        link: 'accounting/accounts-receivable/aging',
        permissionFn: up =>
          andFn(up, ['READ_AR_SUMMARY', 'READ_AR_STMT', 'CREATE_AR_STMT'])
      },
      {
        id: 'Journal Entry',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.ACCOUNTING.LINKNAME.JOURNAL-ENTRY"
            defaultMessage="Journal Entry"
          />
        ),
        link: 'accounting/general-ledger/journal-entries/create',
        permissionFn: up =>
          andFn(up, ['READ_JOURNAL_ENTRY', 'CREATE_JOURNAL_ENTRY'])
      },
      {
        id: 'GL Inquiry',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.ACCOUNTING.LINKNAME.GL-INQUIRY"
            defaultMessage="GL Inquiry"
          />
        ),
        link: 'accounting/general-ledger/inquiry',
        permissionId: 'READ_GENERAL_LEDGER'
      },
      {
        id: 'Financial Statements',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.ACCOUNTING.LINKNAME.FINANCIAL-STATEMENTS"
            defaultMessage="Financial Statements"
          />
        ),
        link: 'financial-statements/statement',
        permissionFn: up =>
          andFn(up, [
            'READ_FINANCIAL_STMT',
            'CALCULATE_FINANCIAL_STMT',
            'RECALCULATE_FINANCIAL_STMT',
            'ARCHIVE_FINANCIAL_STMT',
            'TRANSMIT_FINANCIAL_STMT',
            'DELETE_FINANCIAL_STMT'
          ])
      },
      {
        id: 'Month End Checklist',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.ACCOUNTING.LINKNAME.MONTH-END-CHECKLIST"
            defaultMessage="Month End Checklist"
          />
        ),
        link: 'accounting/month-end-checklist',
        permissionId: 'READ_MONTH_END'
      },
      {
        id: 'Purchase Order Registry',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.ACCOUNTING.LINKNAME.PURCHASE_ORDER_REGISTRY"
            defaultMessage="Purchase Order Registry"
          />
        ),
        link: 'accounting/po-registry',
        permissionId: 'CREATE_PURCHASE_ORDER'
      },
      {
        id: 'ac-reports',
        linkName: (
          <FormattedMessage
            id="flex.menu.accounting-reports"
            defaultMessage="Reports"
          />
        ),
        link: 'accounting/report-landing',
        permissionFn: up =>
          orFn(up, [
            'READ_GENERAL_LEDGER',
            'READ_MONTH_END',
            'READ_FINANCIAL_REPORTS',
            'DOC_REPORT_SETUP_RUN'
          ])
      },
      {
        id: 'Write Checks',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.ACCOUNTING.LINKNAME.WRITE-CHECKS"
            defaultMessage="Write Checks"
          />
        ),
        link: 'accounting/write-check',
        permissionId: 'WRITE_AP_CHECK'
      },
      {
        id: 'ac-check-register',
        linkName: (
          <FormattedMessage
            id="flex.menu.accounting.check-register"
            defaultMessage="Check Register"
          />
        ),
        link: 'accounting/write-checks/check-register',
        permissionFn: up => andFn(up, ['VOID_CHECK', 'VIEW_CHECK_REGISTER'])
      },
      {
        id: 'ac-bank-reconciliation',
        linkName: (
          <FormattedMessage
            id="flex.menu.accounting.bank-reconciliation"
            defaultMessage="Bank Reconciliation"
          />
        ),
        link: 'accounting/bank-reconciliation',
        permissionId: 'MANAGE_ACCOUNTING_SETTINGS'
      },
      {
        id: 'ac-coa-management',
        linkName: (
          <FormattedMessage
            id="flex.menu.accounting.coa-management"
            defaultMessage="Chart Of Accounts"
          />
        ),
        link: 'accounting/coa-management',
        permissionFn: up => orFn(up, ['MANAGE_GL_COA', 'READ_GL_COA'])
      },
      {
        id: 'Accounting Settings',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.ACCOUNTING.LINKNAME.ACCOUNTING-SETTINGS"
            defaultMessage="Accounting Settings"
          />
        ),
        link: 'accounting/accounting-settings',
        permissionId: 'MANAGE_ACCOUNTING_SETTINGS'
      }
    ]
  },
  {
    cardText: (
      <FormattedMessage
        id="FLEX.CORE.DASHBOARD.CUSTOMERS.CARDTEXT.CUSTOMERS"
        defaultMessage="Customers"
      />
    ),
    avatarText: (
      <FormattedMessage
        id="FLEX.CORE.DASHBOARD.CUSTOMERS.AVATAR-TEXT"
        defaultMessage="Cu"
      />
    ),
    link: 'customers',
    permissionId: 'READ_CUSTOMER',
    quickLinks: [
      {
        id: 'View Customers',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.CUSTOMERS.LINKNAME.CUSTOMERS"
            defaultMessage="Customers"
          />
        ),
        link: 'customers',
        permissionId: 'READ_CUSTOMER'
      }
    ]
  },
  {
    cardText: (
      <FormattedMessage
        id="FLEX.CORE.DASHBOARD.PARTS.CARDTEXT.PARTS"
        defaultMessage="Parts"
      />
    ),
    avatarText: (
      <FormattedMessage
        id="FLEX.CORE.DASHBOARD.PARTS.AVATAR-TEXT"
        defaultMessage="Pa"
      />
    ),
    link: 'parts/dashboard',
    permissionId: 'PARTS_FLEX',
    quickLinks: [
      {
        id: 'Inventory',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.PARTS.LINKNAME.INVENTORY"
            defaultMessage="Inventory"
          />
        ),
        link: 'parts/inventory',
        permissionId: 'PARTS_FLEX'
      },
      {
        id: 'Sales',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.PARTS.LINKNAME.SALES"
            defaultMessage="Sales"
          />
        ),
        link: 'parts/sales',
        permissionId: 'READ_SALE'
      },
      {
        id: 'Orders',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.PARTS.LINKNAME.ORDERS"
            defaultMessage="Orders"
          />
        ),
        link: 'parts/orders',
        permissionId: 'READ_ORDER'
      },
      {
        id: 'OEM Parts',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.ADMINISTRATION.LINKNAME.OEMPARTS"
            defaultMessage="OEM - Parts"
          />
        ),
        link: 'parts/oem',
        permissionId: 'PARTS_FLEX'
      }
    ]
  },
  {
    cardText: (
      <FormattedMessage
        id="FLEX.CORE.DASHBOARD.SALES.CARDTEXT.SALES"
        defaultMessage="Sales"
      />
    ),
    avatarText: (
      <FormattedMessage
        id="FLEX.CORE.DASHBOARD.SALES.AVATAR-TEXT"
        defaultMessage="Sa"
      />
    ),
    link: 'sales/deals',
    permissionId: 'SALES_FLEX',
    quickLinks: [
      {
        id: 'My Deals',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.SALES.LINKNAME.MY-DEALS"
            defaultMessage="My Deals"
          />
        ),
        link: 'sales/deals',
        permissionId: 'READ_DEAL'
      },
      {
        id: 'Create Deal',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.SALES.LINKNAME.CREATE-DEAL"
            defaultMessage="Create Deal"
          />
        ),
        link: 'sales/deals/create',
        permissionId: 'CREATE_DEAL'
      },
      {
        id: 'Reports',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.SALES.LINKNAME.SALES-REPORTS"
            defaultMessage="Sales Reports"
          />
        ),
        link: 'sales/reports',
        permissionId: 'CREATE_REPORT'
      }
    ]
  },
  serviceCard(flags),
  {
    cardText: (
      <FormattedMessage
        id="FLEX.CORE.DASHBOARD.VEHICLES.CARDTEXT.VEHICLES"
        defaultMessage="Vehicles"
      />
    ),
    avatarText: (
      <FormattedMessage
        id="FLEX.CORE.DASHBOARD.VEHICLES.AVATAR-TEXT"
        defaultMessage="Ve"
      />
    ),
    link: 'vehicles/inventory',
    permissionId: 'READ_VEHICLE',
    quickLinks: [
      {
        id: 'Find Vehicles',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.VEHICLES.LINKNAME.INVENTORY"
            defaultMessage="Inventory"
          />
        ),
        link: 'vehicles/inventory',
        permissionId: 'READ_VEHICLE'
      },
      {
        id: 'OEM Vehicles',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.ADMINISTRATION.LINKNAME.OEM-VEHICLES"
            defaultMessage="OEM - Vehicles"
          />
        ),
        link: 'vehicles/oem',
        permissionId: 'READ_VEHICLE'
      }
    ]
  },
  {
    cardText: (
      <FormattedMessage
        id="FLEX.CORE.DASHBOARD.PAYROLL.CARDTEXT.PAYROLL"
        defaultMessage="Payroll"
      />
    ),
    avatarText: (
      <FormattedMessage
        id="FLEX.CORE.DASHBOARD.PAYROLL.AVATAR-TEXT"
        defaultMessage="Pr"
      />
    ),
    link: 'payroll',
    permissionId: 'PAYROLL_FLEX',
    quickLinks: [
      {
        id: 'Payroll Plus',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.PAYROLL.LINKNAME.PAYROLL-PLUS"
            defaultMessage="Payroll Plus"
          />
        ),
        link: 'payroll/payrollplus',
        permissionFn: up =>
          andFn(up, ['CREATE_GL_TRANSFER', 'CREATE_EMPLOYEE_MATCHING'])
      },
      {
        id: 'ETC Matching',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.PAYROLL.LINKNAME.ETC-MATCHING"
            defaultMessage="ETC Matching"
          />
        ),
        link: 'payroll/etcmatching',
        permissionId: 'CREATE_EMPLOYEE_MATCHING'
      },
      {
        id: 'GL Transfer',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.PAYROLL.LINKNAME.GL-Transfer"
            defaultMessage="GL Transfer"
          />
        ),
        link: 'payroll/gltransfer',
        permissionId: 'CREATE_GL_TRANSFER'
      }
    ]
  },
  {
    cardText: (
      <FormattedMessage
        id="FLEX.CORE.DASHBOARD.CASHIERING.CARDTEXT.CASHIERING"
        defaultMessage="Cashiering"
      />
    ),
    avatarText: (
      <FormattedMessage
        id="FLEX.CORE.DASHBOARD.CASHIERING.AVATAR-TEXT"
        defaultMessage="Ca"
      />
    ),
    link: 'cashiering',
    permissionId: 'READ_CASHIERING_GENERIC',
    quickLinks: [
      {
        id: 'Cashiering',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.CASHIERING.LINKNAME.CASHIERING"
            defaultMessage="Cashiering"
          />
        ),
        link: 'cashiering',
        permissionFn: userPermissions => {
          const genericPermissions = [
            'READ_CASHIERING_GENERIC',
            'CREATE_CASHIERING_GENERIC'
          ];
          const otherPermissions = [
            'READ_CASHIERING_PARTS',
            'READ_CASHIERING_SERVICE'
          ];

          return (
            genericPermissions.some(p => userPermissions.includes(p)) ||
            otherPermissions.every(p => userPermissions.includes(p))
          );
        }
      }
    ]
  },
  {
    cardText: (
      <FormattedMessage
        id="FLEX.CORE.DASHBOARD.ARCHIVING.CARDTEXT.ARCHIVING"
        defaultMessage="Archiving"
      />
    ),
    avatarText: (
      <FormattedMessage
        id="FLEX.CORE.DASHBOARD.ARCHIVING.AVATAR-TEXT"
        defaultMessage="Ar"
      />
    ),
    link: 'gcm',
    permissionId: 'GCM_SEARCH',
    quickLinks: [
      {
        id: 'Search Content',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.ARCHIVING.LINKNAME.SEARCH-CONTENT"
            defaultMessage="Search Content"
          />
        ),
        link: 'gcm',
        permissionId: 'GCM_SEARCH'
      }
    ]
  },
  {
    cardText: (
      <FormattedMessage
        id="FLEX.CORE.DASHBOARD.ADMINISTRATION.CARDTEXT.ADMINISTRATION"
        defaultMessage="Administration"
      />
    ),
    avatarText: (
      <FormattedMessage
        id="FLEX.CORE.DASHBOARD.ADMINISTRATION.AVATAR-TEXT"
        defaultMessage="Ad"
      />
    ),
    link: 'administration-dashboard',
    permissionId: 'SYSADMIN_FLEX',
    quickLinks: [
      {
        id: 'Employees',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.ADMINISTRATION.LINKNAME.EMPLOYEES"
            defaultMessage="Employees"
          />
        ),
        link: 'employees',
        permissionId: 'READ_EMPLOYEE'
      },
      {
        id: 'FinancialInstitutions',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.ADMINISTRATION.LINKNAME.LENDERS"
            defaultMessage="Lenders"
          />
        ),
        link: 'lenders',
        permissionId: 'READ_FINANCIAL_INSTITUTION'
      },
      {
        id: 'Vendors',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.ADMINISTRATION.LINKNAME.VENDORS"
            defaultMessage="Vendors"
          />
        ),
        link: 'vendors',
        permissionId: 'READ_VENDOR'
      },
      {
        id: 'PrintRegistry',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.ADMINISTRATION.LINKNAME.PRINTERS"
            defaultMessage="Printers"
          />
        ),
        link: 'print-registry',
        permissionId: 'SYSADMIN_FLEX'
      },
      {
        id: 'oem-settings',
        linkName: (
          <FormattedMessage
            id="FLEX.CORE.DASHBOARD.ADMINISTRATION.LINKNAME.OEMSETTINGS"
            defaultMessage="OEM Settings"
          />
        ),
        link: 'oem-settings',
        permissionId: 'SETUP_OEM_INTEGRATION'
      }
    ]
  },
  {
    cardText: (
      <FormattedMessage
        id="FLEX.CORE.DASHBOARD.OEMFILES.CARDTEXT"
        defaultMessage="OEM Files"
      />
    ),
    avatarText: (
      <FormattedMessage
        id="FLEX.CORE.DASHBOARD.OEMFILES.AVATAR-TEXT"
        defaultMessage="Oe"
      />
    ),
    link: 'oem-files',
    permissionId: 'VIEW_FILES_OEM_INTEGRATION',
    quickLinks: []
  }
];

export const dashboardCards = (flags) => getDashboardCards(flags);
