import _ from 'lodash';
import moment from 'moment';
import { flattenObject } from 'utils';
import i18nMessages from '../i18nMessages';
import {
  API_DATE_FORMAT,
  IN_STOCK,
  MANUFACTURER,
  NEW,
  USED,
  RENTAL,
  MISC,
  NEW_JOURNAL,
  ERROR_STRING_MAPPINGS
} from './constants';

const normalizeVehicleApiToUI = vehicle => {
  const invoiceDate = _.get(vehicle, 'inventoryDetails.invoiceDate')
    ? moment(vehicle.inventoryDetails.invoiceDate)
    : '';
  const entryDate = _.get(vehicle, 'inventoryDetails.entryDate')
    ? moment(vehicle.inventoryDetails.entryDate)
    : '';
  const lotArrivalDate = _.get(vehicle, 'inventoryDetails.lotArrivalDate')
    ? moment(vehicle.inventoryDetails.lotArrivalDate)
    : '';

  const advertisedPrice = _.get(vehicle, 'pricing.advertisedPrice') || 0;
  const baseMsrp = _.get(vehicle, 'pricing.baseMsrp') || 0;
  const retailPrice = _.get(vehicle, 'pricing.retailPrice') || 0;
  const stickerMsrp = _.get(vehicle, 'pricing.stickerMsrp') || 0;
  return {
    ...defaultUIVehicle,
    ...vehicle,
    cost: {
      ...vehicle.cost,
      invoiceDate
    },
    inventoryDetails: {
      ...vehicle.inventoryDetails,
      entryDate,
      lotArrivalDate
    },
    pricing: {
      advertisedPrice,
      baseMsrp,
      retailPrice,
      stickerMsrp
    },
    body: {
      abbreviation: vehicle.body || ''
    }
  };
};

const normalizeVehicleUIToApi = vehicle => {
  const invoiceDate = _.get(vehicle, 'inventoryDetails.invoiceDate')
    ? moment(vehicle.inventoryDetails.invoiceDate).format(API_DATE_FORMAT)
    : '';
  const entryDate = _.get(vehicle, 'inventoryDetails.entryDate')
    ? moment(vehicle.inventoryDetails.entryDate).format(API_DATE_FORMAT)
    : '';
  const lotArrivalDate = _.get(vehicle, 'inventoryDetails.lotArrivalDate')
    ? moment(vehicle.inventoryDetails.lotArrivalDate).format(API_DATE_FORMAT)
    : '';

  return {
    ...vehicle,
    modelNumber: vehicle.modelNumber.substring(0, 16),
    cost: {
      ...vehicle.cost,
      invoiceDate
    },
    inventoryDetails: {
      ...vehicle.inventoryDetails,
      entryDate,
      lotArrivalDate
    },
    body: _.get(vehicle, 'body.abbreviation', '')
  };
};

const normalizeCostDetailsUIToApi = ({ accountingDetail }) => ({
  ...accountingDetail
});

const defaultUIVehicle = {
  stockType: NEW,
  stockStatus: IN_STOCK,
  inventoryDetails: {
    acquisitionType: MANUFACTURER,
    entryDate: moment(),
    lotArrivalDate: moment()
  },
  options: [],
  pricing: {
    advertisedPrice: 0,
    baseMsrp: 0,
    retailPrice: 0,
    stickerMsrp: 0
  }
};

const getDefaultUICostDetails = (vehicle, settings) => ({
  accountingDetail: {
    baseVehicleCost: 0,
    currentCost: 0,
    dealerOptionsCost: 0,
    destination: 0,
    factoryOptionsCost: 0,
    floorplanAmount: 0,
    holdback: 0,
    options: [],
    originalCost: 0,
    pack: 0,
    saleAccount: '',
    allowPosting: true
  },
  additionalCosts: [],
  journalDetails: {
    journalId: NEW_JOURNAL,
    accountingDate: moment()
  },
  destinationAccounts: {
    advertising: _.get(settings, 'defaultAdvertisingAccount', ''),
    holdback: _.get(settings, 'defaultDealerHoldBackAccount', ''),
    inventoryCost: _.get(vehicle, 'cost.inventoryAccountNumber', ''),
    dealerCost: _.get(vehicle, 'cost.inventoryAccountNumber', '')
  }
});

const getErrorStringFromErrorsObject = (errors, intl) => {
  const getFieldsString = errorObject =>
    Object.keys(errorObject)
      .map(key => {
        const fieldMessage = _.get(ERROR_STRING_MAPPINGS, key, '');
        return fieldMessage.id ? intl.formatMessage(fieldMessage) : key;
      })
      .join(', ')
      .replace(/,(?=[^,]*$)/, intl.formatMessage(i18nMessages.and));
  const getSingleFieldError = (errorObject, errorString) => {
    const key = Object.keys(errorObject)[0];
    const fieldMessage = _.get(ERROR_STRING_MAPPINGS, key, '');
    const field = fieldMessage.id ? intl.formatMessage(fieldMessage) : key;
    return intl.formatMessage(errorString, { field });
  };
  const getMultipleFieldsError = (errorObject, errorString) => {
    const fields = getFieldsString(errorObject);
    return intl.formatMessage(errorString, { fields });
  };

  const formErrors = [];
  const {
    vehicle = {},
    journalDetails = {},
    destinationAccounts = {}
  } = errors;

  const vehicleErrors = flattenObject({ ...vehicle, ...journalDetails } || {});
  if (Object.keys(vehicleErrors).length > 1) {
    formErrors.push(
      getMultipleFieldsError(
        vehicleErrors,
        i18nMessages.theFollowingFieldsAreRequired
      )
    );
  } else if (Object.keys(vehicleErrors).length === 1) {
    formErrors.push(
      getSingleFieldError(vehicleErrors, i18nMessages.fieldIsRequired)
    );
  }

  const destinationAccountErrors = flattenObject(destinationAccounts || {});
  if (Object.keys(destinationAccountErrors).length > 1) {
    formErrors.push(
      getMultipleFieldsError(
        destinationAccountErrors,
        i18nMessages.accountsAreRequiredForTheFollowing
      )
    );
  } else if (Object.keys(destinationAccountErrors).length === 1) {
    formErrors.push(
      getSingleFieldError(
        destinationAccountErrors,
        i18nMessages.accountIsRequiredFor
      )
    );
  }

  return formErrors.join('\n');
};

const getIsVehicleUsedRentalOrMisc = vehicle => {
  if (!vehicle) return false;
  const vehicleStock = vehicle.stockType || NEW;
  return (
    vehicleStock === USED || vehicleStock === RENTAL || vehicleStock === MISC
  );
};

const validateCostDetails = ({ costDetails, vehicle }) => {
  const { accountingDetail, destinationAccounts, journalDetails } = costDetails;
  const errors = {};
  if (!_.get(journalDetails, 'journalId', '')) {
    errors.journalDetails = { ...errors.journalDetails, journalId: true };
  }
  if (!_.get(journalDetails, 'accountingDate', '')) {
    errors.journalDetails = { ...errors.journalDetails, accountingDate: true };
  }

  if (!getIsVehicleUsedRentalOrMisc(vehicle)) {
    if (
      !_.get(destinationAccounts, 'advertising', '') &&
      !!_.get(accountingDetail, 'advertising', 0)
    ) {
      errors.destinationAccounts = {
        ...errors.destinationAccounts,
        advertising: true
      };
    }
    if (
      !_.get(destinationAccounts, 'holdback', '') &&
      !!_.get(accountingDetail, 'holdback', 0)
    ) {
      errors.destinationAccounts = {
        ...errors.destinationAccounts,
        holdback: true
      };
    }
    if (
      !_.get(destinationAccounts, 'hardPack', '') &&
      !!_.get(accountingDetail, 'hardPack', 0)
    ) {
      errors.destinationAccounts = {
        ...errors.destinationAccounts,
        hardPack: true
      };
    }
    if (!_.get(destinationAccounts, 'inventoryCost', '')) {
      errors.destinationAccounts = {
        ...errors.destinationAccounts,
        inventoryCost: true
      };
    }
    if (!_.get(destinationAccounts, 'invoiceAmount', '')) {
      errors.destinationAccounts = {
        ...errors.destinationAccounts,
        invoiceAmount: true
      };
    }
  } else {
    if (
      !_.get(destinationAccounts, 'baseVehicleCost', '') &&
      !!_.get(accountingDetail, 'baseVehicleCost', 0)
    ) {
      errors.destinationAccounts = {
        ...errors.destinationAccounts,
        baseVehicleCost: true
      };
    }
    if (
      !_.get(destinationAccounts, 'pack', '') &&
      !!_.get(accountingDetail, 'pack', 0)
    ) {
      errors.destinationAccounts = {
        ...errors.destinationAccounts,
        pack: true
      };
    }
    if (!_.get(destinationAccounts, 'dealerCost', '')) {
      errors.destinationAccounts = {
        ...errors.destinationAccounts,
        dealerCost: true
      };
    }
  }

  return errors;
};

export {
  getDefaultUICostDetails,
  defaultUIVehicle,
  getErrorStringFromErrorsObject,
  getIsVehicleUsedRentalOrMisc,
  normalizeCostDetailsUIToApi,
  normalizeVehicleApiToUI,
  normalizeVehicleUIToApi,
  validateCostDetails
};
