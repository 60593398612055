import isFunction from 'lodash/isFunction';
import { combineReducers } from 'redux';
import { uiReducers as ui } from './general.reducers';
import { default as i18n } from './i18n.reducer';

// Inject the initial state for async loaded reducers
const loadState = (reducers, oldState = {}) =>
  Object.keys(reducers).reduce((reducer, key) => {
    if (isFunction(reducers[key])) {
      /* eslint-disable */
      reducer[key] = (state = oldState[key], action) =>
        reducers[key](state, action);
      /* eslint-enable */
    }
    return reducer;
  }, {});

export const createReducer = (asyncReducers, initialState) =>
  combineReducers(
    loadState(
      {
        ui,
        i18n,
        ...asyncReducers
      },
      initialState
    )
  );
