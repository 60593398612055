import { defineMessages } from 'react-intl';

const prefix = 'FLEX.CORE.VEHICLE';

export default defineMessages({
  glCost: {
    id: `${prefix}.SEARCH.HEADING.GL_COST`,
    defaultMessage: 'GL Cost'
  },
  stockNum: {
    id: `${prefix}.SEARCH.HEADING.STOCK_NUMBER`,
    defaultMessage: 'Stock #'
  },
  stockType: {
    id: `${prefix}.SEARCH.HEADING.VEHICLE_STOCK_TYPE`,
    defaultMessage: 'Stock Type'
  },
  vin: {
    id: `${prefix}.SEARCH.HEADING.VEHICLE_VIN`,
    defaultMessage: 'VIN'
  },
  year: {
    id: `${prefix}.SEARCH.HEADING.VEHICLE_YEAR`,
    defaultMessage: 'Year'
  },
  make: {
    id: `${prefix}.SEARCH.HEADING.VEHICLE_MAKE`,
    defaultMessage: 'Make'
  },
  model: {
    id: `${prefix}.SEARCH.HEADING.VEHICLE_MODEL`,
    defaultMessage: 'Model'
  },
  modelNumber: {
    id: `${prefix}.SEARCH.HEADING.VEHICLE_MODEL_NUMBER`,
    defaultMessage: 'Model #'
  },
  trim: {
    id: `${prefix}.SEARCH.HEADING.VEHICLE_TRIM`,
    defaultMessage: 'Trim'
  },
  totalMSRP: {
    id: `${prefix}.SEARCH.HEADING.VEHICLE_TOTAL_MSRP`,
    defaultMessage: 'Total MSRP'
  },
  advertisedPrice: {
    id: `${prefix}.SEARCH.HEADING.VEHICLE_ADVERTISING`,
    defaultMessage: 'Advertised Price'
  },
  engine: {
    id: `${prefix}.SEARCH.HEADING.VEHICLE ENGINE`,
    defaultMessage: 'Engine'
  },
  titleNo: {
    id: `${prefix}.SEARCH.HEADING.TITLE_NO`,
    defaultMessage: 'Title No.'
  },
  titleSt: {
    id: `${prefix}.SEARCH.HEADING.TITLE_ST`,
    defaultMessage: 'Title St.'
  },
  titleInPoss: {
    id: `${prefix}.SEARCH.HEADING.TITLE_IN_POSS`,
    defaultMessage: 'Title In Poss.'
  },
  exteriorColor: {
    id: `${prefix}.SEARCH.HEADING.VEHICLE_COLOR_EXTERIOR`,
    defaultMessage: 'Exterior Color'
  },
  dateAdded: {
    id: `${prefix}.SEARCH.HEADING.DATE_ADDED`,
    defaultMessage: 'Date Added'
  },
  days: {
    id: `${prefix}.SEARCH.HEADING.DAYS`,
    defaultMessage: 'Days'
  },
  inventoryStatus: {
    id: `${prefix}.SEARCH.HEADING.INVENTORY_STATUS`,
    defaultMessage: 'Inventory'
  },
  titleAllVehicles: {
    id: `${prefix}.GRID.TITLE.ALL_VEHICLES`,
    defaultMessage: 'All Vehicles'
  },
  titleFilterResults: {
    id: `${prefix}.GRID.TITLE.RESULTS`,
    defaultMessage: 'Results'
  },
  fileName: {
    id: `${prefix}.FILE_NAME`,
    defaultMessage: 'Vehicles'
  },
  odometer: {
    id: `${prefix}.SEARCH.HEADING.ODOMETER`,
    defaultMessage: 'Odometer'
  },
  no: {
    id: `${prefix}.SEARCH.HEADING.NO`,
    defaultMessage: 'No'
  },
  yes: {
    id: `${prefix}.SEARCH.HEADING.YES`,
    defaultMessage: 'Yes'
  },
  missingTitleInformation: {
    id: `${prefix}.FILTER.CHIP.MISSING_TITLE_INFORMATION`,
    defaultMessage: 'Missing Title Information'
  },
  withAllTitleInformation: {
    id: `${prefix}.FILTER.CHIP.WITH_ALL_TITLE_INFORMATION`,
    defaultMessage: 'With all Title Information'
  },
  missingLotArrivalDate: {
    id: `${prefix}.FILTER.CHIP.MISSING_LOT_INFORMATION`,
    defaultMessage: 'Missing Lot Arrival Date'
  },
  onLot: {
    id: `${prefix}.FILTER.CHIP.ON_LOT`,
    defaultMessage: 'On Lot'
  },
  lessThan10K: {
    id: `${prefix}.FILTER.CHIP.PRICE_LESS_THAN_10K`,
    defaultMessage: 'Less Than 10K'
  },
  over100K: {
    id: `${prefix}.FILTER.CHIP.PRICE_OVER_100K`,
    defaultMessage: 'Over 100K'
  },
  acquisitionType: {
    id: `${prefix}.FILTER.CHIP.ACQUISITION_TYPE`,
    defaultMessage: 'Acquisition Type'
  },
  createDeal: {
    id: `${prefix}.HEADING.CREATE_A_DEAL`,
    defaultMessage: 'Sales'
  },
  startRO: {
    id: `${prefix}.HEADING.START_AN_RO`,
    defaultMessage: 'Service'
  },
  vehicleListTitle: {
    id: `${prefix}.LIST.TITLE`,
    defaultMessage: 'Vehicles'
  },
  addVehicleTitle: {
    id: `${prefix}.ADD.TITLE`,
    defaultMessage: 'Add Vehicle'
  },
  updateVehicleTitle: {
    id: `${prefix}.UPDATE.TITLE`,
    defaultMessage: 'Vehicle {stockNumber}'
  },
  createDealAction: {
    id: `${prefix}.LINK.CREATE_A_DEAL`,
    defaultMessage: 'Create a Deal'
  },
  startROAction: {
    id: `${prefix}.QUICK_ACTION.START_AN_RO`,
    defaultMessage: 'Start an RO'
  },
  notAvailable: {
    id: `${prefix}.CELL.NOT_AVAIALABLE`,
    defaultMessage: 'N/A'
  },
  dateAddedFormat: {
    id: `${prefix}.CELL.DATE_ADDED_FORMAT`,
    defaultMessage: 'MM/DD/YYYY HH:mm:ss'
  },
  daysInTable: {
    id: `${prefix}.DAYS`,
    defaultMessage: '{itemCount, plural, =0 {days} one {day} other {days}}'
  },
  stockTypeNew: {
    id: `${prefix}.STOCK_TYPE.NEW`,
    defaultMessage: 'NEW'
  },
  stockTypeUsed: {
    id: `${prefix}.STOCK_TYPE.USED`,
    defaultMessage: 'USED'
  },
  stockTypeRental: {
    id: `${prefix}.STOCK_TYPE.RENTAL`,
    defaultMessage: 'RENTAL'
  },
  stockTypeDemo: {
    id: `${prefix}.STOCK_TYPE.DEMO`,
    defaultMessage: 'DEMO'
  },
  stockTypeMiscellaneous: {
    id: `${prefix}.STOCK_TYPE.MISCELLANEOUS`,
    defaultMessage: 'MISCELLANEOUS'
  },
  lotName: {
    id: `${prefix}.LOT_NAME`,
    defaultMessage: 'Lot Name'
  },
  lotLocation: {
    id: `${prefix}.LOT_LOCATION`,
    defaultMessage: 'Lot Location'
  },
  downloadPDF: {
    id: `${prefix}.DOWNLOAD.PDF`,
    defaultMessage: 'Download PDF'
  },
  downloadingPDF: {
    id: `${prefix}.DOWNLOADING.PDF`,
    defaultMessage: 'Downloading PDF'
  },
  downloadCSV: {
    id: `${prefix}.DOWNLOAD.CSV`,
    defaultMessage: 'Download CSV'
  },
  printPDF: {
    id: `${prefix}.PRINT.PDF`,
    defaultMessage: 'Print PDF'
  },
  vehicleListPrintReportTitle: {
    id: `${prefix}.REPORT.TITLE`,
    defaultMessage:
      'Vehicle Inventory Report\nCreated on:{month}/{day}/{year} {hour}:{minutes}'
  },
  storeMetaData: {
    id: `${prefix}.STORE_INFORMATION`,
    defaultMessage: 'Store: {companyId} - {name}'
  },
  printDialogText: {
    id: `${prefix}.PRINT_DIALOG.HEADER_TEXT`,
    defaultMessage: 'Print'
  }
});
