// Need to add support for searching dates
const ALLOWED_TYPES = ['string', 'number', 'boolean'];
const SEARCHABLE_TYPES = [...ALLOWED_TYPES, 'object'];

/**
 * String contain helper
 *
 * @param {*} val
 * @param {String} search
 */
function contains(val, search) {
  if (typeof val !== 'object') {
    // partial matches instead of exact
    // TODO: A better mechanism is needed for this
    return search.toLowerCase().indexOf(val.toLowerCase()) >= 0;
  }

  return Object.values(val).filter(item => contains(item, search));
}

/**
 * Filter an array of objects by the given phrase
 *
 * @export
 * @param {Array} array
 * @param {String} filter
 * @returns Array
 */
export const filterBy = (array, filter) => {
  if (!filter || ALLOWED_TYPES.indexOf(typeof array) !== -1) {
    return array;
  }

  return array.filter(item => {
    if (typeof item === 'object') {
      return (
        Object.values(item)
          .filter(value => SEARCHABLE_TYPES.indexOf(typeof value) !== -1)
          .filter(value => contains(value, filter)).length > 0
      );
    }

    return contains(item, filter);
  });
};
