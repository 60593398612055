import { ApiHandler2 as makeReq } from '@cdk-flex/components-api-handler';

const VENDORS_BASE_URL =
  process.env.REACT_APP_VENDOR_API_ENDPOINT || 'ds-flex-vendor/v1/vendors';

export const vendorAPI = {
  getVendors() {
    return makeReq({
      url: `${VENDORS_BASE_URL}`,
      method: 'GET'
    });
  }
};

export const vendorSearchAPI = {
  searchVendors(query) {
    return makeReq({
      url: `${VENDORS_BASE_URL}/search?query=${query}`,
      method: 'GET'
    });
  }
};
