import { ApiHandlerRaw } from '@cdk-flex/components-api-handler';

const SALES_BASE_URL =
  process.env.REACT_APP_SALES_API_ENDPOINT || 'ds-flex-sales/v1';

const salesApi = {
  updateVehicleCost: stockNumber => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return ApiHandlerRaw({
      url: `${SALES_BASE_URL}/event/vehicle-cost-updated`,
      method: 'POST',
      headers,
      data: {
        stockNumber
      }
    });
  }
};

export default salesApi;
