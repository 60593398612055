import { combineReducers } from 'redux';
import types from './types';

function normalizeLocale(locale = '') {
  return locale.replace('_', '-');
}

const language = (state = 'en-US', action) => {
  switch (action.type) {
    case types.I18N_LANGUAGE:
      return action.language;
    case types.LOAD_IDENTITY:
      return normalizeLocale(action.identity.user.locale);
    default:
      return state;
  }
};

const locale = (state = 'en-US', action) => {
  switch (action.type) {
    case types.I18N_LOCALE:
      return action.locale;
    case types.LOAD_IDENTITY:
      return normalizeLocale(action.identity.user.locale);
    default:
      return state;
  }
};

const currency = (state = 'USD', action) => {
  switch (action.type) {
    case types.I18N_LOCALE:
      switch (action.locale) {
        case 'en-US':
          return 'USD';
        case 'fr-CA':
          return 'CAD';
        default:
          return 'USD';
      }
    default:
      return state;
  }
};

export default combineReducers({
  language,
  locale,
  currency
});

export const setLanguage = l => ({
  type: types.I18N_LANGUAGE,
  language: l
});

export const setLocale = l => ({ type: types.I18N_LOCALE, locale: l });
