const onePayConstants = {
  admin: 'admin',
  payments: 'payments',
  paymentMappings: 'paymentMappings',
  employeeIdKey: 'employee',
  storeIdKey: 'store_id',
  paymentType: 'OnePay',
  user: 'csr',
  successPaymentStatus: 'SUCCESS',
  defaultCallbackPaymentType: 'CASH'
};

export default onePayConstants;
