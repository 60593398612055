import React, { Component } from 'react';
import { func, node } from 'prop-types';
import { Loader } from './Loader';

const initialState = {
  isPending: true
};

class ComponentLoader extends Component {
  static propTypes = {
    /** prop trying to render */
    component: node,
    /** Async function that loads the necessary data for component */
    onFetchAsync: func
  };

  state = initialState;

  async componentDidMount() {
    await this.props.onFetchAsync().then(() => {
      this.setState({ isPending: false });
    });
  }

  render() {
    const { isPending } = this.state;
    if (isPending) {
      return <Loader />;
    }
    return this.props.component;
  }
}

export default ComponentLoader;
