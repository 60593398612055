import React from 'react';
import 'babel-polyfill';
import { connect } from 'react-redux';
import { Router } from 'react-router-dom';
import PrintProvider from 'react-easy-print';
import { ThemeProvider } from 'styled-components';
import { theme } from 'cdk-radial';
import { Flex } from '@cdk-flex/components-flex';
import { createBrowserHistory } from 'history';
import { get } from 'lodash';
import { Toasts } from 'components';
import { loadLocaleData, loadMessages } from 'i18n';
import Routes from './Routes';

const history = createBrowserHistory();

const App = () => (
  <ThemeProvider theme={theme}>
    <PrintProvider>
      <Flex
        history={history}
        loadLocaleData={loadLocaleData}
        loadMessages={loadMessages}
      >
        <React.StrictMode>
          <Router history={history}>
            <Routes />
          </Router>
        </React.StrictMode>
        <Toasts />
      </Flex>
    </PrintProvider>
  </ThemeProvider>
);

const mappedState = state => ({
  /* Toggles whether to hide the header bar and nav menu for a component that fills the whole screen */
  fullScreenComponent: state.ui.fullScreenComponent,
  allowedPermissions: get(state, 'identity.permissions'),
  language: state.i18n.language,
  locale: state.i18n.locale
});

export default connect(mappedState)(App);
