import {
  ApiHandler2 as makeReq,
  ApiHandler as makeRequest
} from '@cdk-flex/components-api-handler';

const PARTS_API_ENDPOINT =
  process.env.REACT_APP_PARTS_API_ENDPOINT || 'ds-flex-parts/v1';

const employeeId = JSON.parse(window.sessionStorage.getItem('employee'));

const partsApi = {
  getReadPayments: invoiceNumber =>
    makeReq({
      url: `${PARTS_API_ENDPOINT}/parts-invoice/read-payments/${invoiceNumber}`,
      method: 'GET'
    }),
  paymentCashiering: data =>
    makeRequest({
      url: `${PARTS_API_ENDPOINT}/parts-invoice/cashiering`,
      method: 'POST',
      data
    }),
  getSaleTypes: () =>
    makeReq({
      url: `${PARTS_API_ENDPOINT}/parts-invoice/saletypes`,
      method: 'GET'
    }),
  closeInvoice: invoiceNumber =>
    makeReq({
      url: `${PARTS_API_ENDPOINT}/parts-invoice/close/${invoiceNumber}/${employeeId}`,
      method: 'GET'
    }),
  getInvoiceDetail: invoiceNumber =>
    makeReq({
      url: `${PARTS_API_ENDPOINT}/parts-invoice/read-quote-or-invoice-header/I?invoiceNumber=${invoiceNumber}`,
      method: 'GET'
    })
};

export default partsApi;
