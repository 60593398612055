import { defineMessages } from 'react-intl';

const prefix = 'FLEX.CORE.VEHICLE';

export default defineMessages({
  fieldIsRequired: {
    id: `${prefix}.FIELD_IS_REQUIRED`,
    defaultMessage: '{field} is required.'
  },
  theFollowingFieldsAreRequired: {
    id: `${prefix}.THE_FOLLOWING_FIELDS_ARE_REQUIRED`,
    defaultMessage: 'The following fields are required: {fields}.'
  },
  and: {
    id: `${prefix}.AND`,
    defaultMessage: ' and'
  },
  accountIsRequiredFor: {
    id: `${prefix}.ACCOUNT_IS_REQUIRED_FOR`,
    defaultMessage: 'Account is required for {field}.'
  },
  accountsAreRequiredForTheFollowing: {
    id: `${prefix}.ACCOUNTS_ARE_REQUIRED_FOR_THE_FOLLOWING`,
    defaultMessage: 'Accounts are required for the following: {fields}.'
  },
  vin: {
    id: `${prefix}.VIN`,
    defaultMessage: 'VIN'
  },
  odometer: {
    id: `${prefix}.ODOMETER`,
    defaultMessage: 'Odometer'
  },
  year: {
    id: `${prefix}.YEAR`,
    defaultMessage: 'Year'
  },
  make: {
    id: `${prefix}.MAKE`,
    defaultMessage: 'Make'
  },
  model: {
    id: `${prefix}.MODEL`,
    defaultMessage: 'Model'
  },
  exteriorColor: {
    id: `${prefix}.EXTERIOR_COLOR`,
    defaultMessage: 'Exterior Color'
  },
  stockNumber: {
    id: `${prefix}.STOCK_NUMBER`,
    defaultMessage: 'Stock Number'
  },
  inventoryAccountNumber: {
    id: `${prefix}.INVENTORY_ACCOUNT_NUMBER`,
    defaultMessage: 'Inventory Account Number'
  },
  acquisitionType: {
    id: `${prefix}.ACQUISITION_TYPE`,
    defaultMessage: 'Acquisition Type'
  },
  entryDate: {
    id: `${prefix}.ENTRY_DATE`,
    defaultMessage: 'Entry Date'
  },
  advertising: {
    id: `${prefix}.ADVERTISING`,
    defaultMessage: 'Advertising'
  },
  holdback: {
    id: `${prefix}.HOLDBACK`,
    defaultMessage: 'Holdback'
  },
  hardPack: {
    id: `${prefix}.HARD_PACK`,
    defaultMessage: 'Hard Pack'
  },
  initialInventoryCost: {
    id: `${prefix}.INITIAL_INVENTORY_COST`,
    defaultMessage: 'Initial Inventory Cost'
  },
  totalInvoiceAmount: {
    id: `${prefix}.TOTAL_INVOICE_AMOUNT`,
    defaultMessage: 'Total Invoice Amount'
  },
  acquisitionCost: {
    id: `${prefix}.ACQUISITION_COST`,
    defaultMessage: 'Acquisition Cost'
  },
  actualCashValue: {
    id: `${prefix}.ACTUAL_CASH_VALUE`,
    defaultMessage: 'Actual Cash Value'
  },
  journal: {
    id: `${prefix}.JOURNAL`,
    defaultMessage: 'Journal'
  },
  accountingDate: {
    id: `${prefix}.ACCOUNTING_DATE`,
    defaultMessage: 'Accounting Date'
  },
  loading: {
    id: `${prefix}.LOADING`,
    defaultMessage: 'Loading...'
  },
  vehicleDetails: {
    id: `${prefix}.VEHICLE_DETAILS`,
    defaultMessage: 'Vehicle Details'
  },
  priceAndCost: {
    id: `${prefix}.PRICE_AND_COST`,
    defaultMessage: 'Price & Cost'
  },
  tabBetweenVehicleDetailsVehicleCost: {
    id: `${prefix}.TAB_BETWEEN_VEHICLE_DETAILS_AND_VEHICLE_COST`,
    defaultMessage: 'Tab between Vehicle Details and Vehicle Cost'
  },
  addVehicle: {
    id: `${prefix}.ADD_VEHICLE`,
    defaultMessage: 'Add Vehicle'
  },
  startDeal: {
    id: `${prefix}.START_DEAL`,
    defaultMessage: 'START DEAL'
  },
  startRepairOrder: {
    id: `${prefix}.START_REPAIR_ORDER`,
    defaultMessage: 'START REPAIR ORDER'
  },
  save: {
    id: `${prefix}.SAVE`,
    defaultMessage: 'SAVE'
  },
  unableToLoadStockNumberVehicle: {
    id: `${prefix}.UNABLE_TO_LOAD_STOCK_NUMBER_VEHICLE`,
    defaultMessage:
      'Unable to load {stockNumber} vehicle. Please retry. If issue persists, contact support.'
  },
  dismiss: {
    id: `${prefix}.DISMISS`,
    defaultMessage: 'Dismiss'
  }
});
