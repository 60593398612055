import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { PageHeaderPrevious } from '@cdk-flex/components-page';
import i18n from './i18nMessages';

const BackToAdmin = ({ history }) => (
  <PageHeaderPrevious
    handleNav={() => history.push('/administration-dashboard')}
  >
    <FormattedMessage {...i18n.backToAdmin} />
  </PageHeaderPrevious>
);

BackToAdmin.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default BackToAdmin;
