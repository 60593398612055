// Needs love
/**
 * @name deepObjVal()
 *
 * @desc
 * Will return values from a nested object based on a dot-delimited string
 * Useful when we don't know the data structure upfront.
 * Using "user.0.age" works to access items inside an array
 *
 * @param {Object} obj The object where we will extract values from
 * @param {String} key The path to the desired value
 *
 * @example
 * const sourceObj = {
 *  name: "Daniel Perez",
 *  props: {
 *    age: 26,
 *    city: "Miami"
 * };
 *
 * deepObjVal(sourceObj, "name.props.age")
 *
 * //-> 26
 *
 */
export const deepObjVal = (obj, path) =>
  path.split('.').reduce((prev, curr) => (prev ? prev[curr] : undefined), obj);
