import React, { Component } from 'react';
import Waypoint from 'react-waypoint';
import { object, string } from 'prop-types';
import { get } from 'lodash';

class ScrollSpy extends Component {
  render() {
    const { id } = this.props;
    const enter = get(this.context, 'scrollSpy.enter');

    if (!enter) {
      console.error(
        'Attempted to use ScrollSpy outside of context. An ancestor of ScrollSpy needs to initialize the context'
      );
      return this.props.children;
    }

    return (
      <Waypoint
        onEnter={() => enter(id)}
        /**
         * These offsets provide a thin strip 5% the height of the
         * scrollable area near the top.  The idea is that the element
         * that currently overlaps this thin strip will be the one that
         * is the current in the ScrollSpy context.
         */
        topOffset="5%"
        bottomOffset="90%"
        /**
         * React waypoint tries to ensure that all enter events are
         * received by default, but when we jump from one tab link
         * to another, we may pass over a few waypoints on the way,
         * and we'll get enter events in rapid succession and probably
         * not in the order we want them.  To avoid this, we set
         * fireOnRapidScroll to false.  If this isn't good enough, then
         * we may need to assert ourselves that the waypoint is actually
         * still within the offsets before calling enter() on the context.
         */
        fireOnRapidScroll={false}
      >
        {this.props.children}
      </Waypoint>
    );
  }

  static propTypes = {
    /* Element ID that is passed back in the callbacks */
    id: string.isRequired
  };

  static contextTypes = {
    scrollSpy: object
  };
}

export default ScrollSpy;
