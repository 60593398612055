import React from 'react';
import { connect } from 'react-redux';
import { CircularProgress } from '@cdk-uip/react-circular-progress';
import classnames from 'classnames';
import './loader.less';

const Loader = ({ loading = 0, large }) =>
  loading > 0 && (
    <div className={classnames('loading-animation', { large })}>
      <CircularProgress />
    </div>
  );

export default connect(({ ui }) => ({ loading: ui.loading }))(Loader);
