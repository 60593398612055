import { ApiHandler2 as makeReq } from '@cdk-flex/components-api-handler';

const EMPLOYEE_BASE_URL =
  process.env.REACT_APP_EMPLOYEE_API_ENDPOINT ||
  'ds-flex-employee/v1/employees';
const endpoint = `${EMPLOYEE_BASE_URL}`;

const employeeAPI = {
  getEmployees() {
    return makeReq({
      url: `${endpoint}/list`,
      method: 'GET'
    });
  },
  getEmployee(id = null) {
    return makeReq({
      url: `${endpoint}/${id}`,
      method: 'GET'
    });
  },
  getTechnician(id = null) {
    return makeReq({
      url: `${endpoint}/${id}/technician`,
      method: 'GET'
    });
  },
  updateEmployee(data) {
    return makeReq({
      method: 'POST',
      url: `${endpoint}/update/${data.id}`,
      data
    });
  },
  createEmployee(data) {
    return makeReq({
      method: 'POST',
      url: `${endpoint}/create`,
      data
    });
  },
  updateTechnician(data) {
    return makeReq({
      method: 'POST',
      url: `${endpoint}/${data.id}/technician`,
      data: data.serviceDept.technician
    });
  },
  fetchUnassigned() {
    return makeReq({
      url: `${endpoint}/users/unassigned`,
      method: 'GET'
    });
  },
  getTechnicianNew(id = null) {
    return makeReq({
      url: `ds-flex-employee/v1/v2/employees/${id}/technician`,
      method: 'GET'
    });
  },
  updateTechnicianNew(data) {
    return makeReq({
      url: `ds-flex-employee/v1/v2/employees/${data.id}/technician`,
      method: 'POST',
      data: data.serviceDept.technician
    });
  },
  getValidSupervisors: () => {
    return makeReq({
      url: `ds-flex-employee/v1/etc/list`,
      method: 'GET'
    });
  }
};

export default employeeAPI;
