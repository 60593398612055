import _ from 'lodash';

const ConditionallyRendered = ({ children, isRendered }) => {
  if (!isRendered) return null;
  return children;
};

const flattenObject = (obj, roots = [], sep = '.') =>
  Object.keys(obj).reduce(
    (memo, prop) =>
      Object.assign(
        {},
        memo,
        Object.prototype.toString.call(obj[prop]) === '[object Object]'
          ? flattenObject(obj[prop], roots.concat([prop]))
          : { [roots.concat([prop]).join(sep)]: obj[prop] }
      ),
    {}
  );

const paymentTypeOptions = (processMappings, cashReceiptsPaymentTypes) => {
  const paymentMappings = _.get(
    processMappings,
    'processorDetails[0].mappings',
    undefined
  );

  if (!processMappings || !paymentMappings) return cashReceiptsPaymentTypes;

  const paymentOptions = cashReceiptsPaymentTypes.filter(
    item =>
      !paymentMappings.some(({ intPaymentType }) => intPaymentType === item)
  );

  return paymentMappings.length
    ? [...paymentOptions, 'OnePay']
    : paymentOptions;
};

export { ConditionallyRendered, flattenObject, paymentTypeOptions };
