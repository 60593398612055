import { ApiHandler2 as makeReq } from '@cdk-flex/components-api-handler';

const CASHIERING_BASE_URL =
  process.env.REACT_APP_CASHIERING_API_ENDPOINT ||
  'ds-flex-cashiering/v1/cashiering';

const cashieringAPI = {
  getInvoices: () =>
    makeReq({
      url: `${CASHIERING_BASE_URL}/listPartsInvoices`,
      method: 'GET'
    }),
  getRepairOrders: () =>
    makeReq({
      url: `${CASHIERING_BASE_URL}/listServiceRepairOrders`,
      method: 'GET'
    }),
  getAll: () =>
    makeReq({
      url: `${CASHIERING_BASE_URL}/listServiceRepairOrdersAndPartsInvoices`,
      method: 'GET'
    }),
  postToEforms: data =>
    makeReq({
      url: `${CASHIERING_BASE_URL}/post-form-archive`,
      method: 'POST',
      data
    })
};

export default cashieringAPI;
