import { ApiHandler2 as makeReq } from '@cdk-flex/components-api-handler';

const SERVICE_API_ENDPOINT =
  process.env.REACT_APP_SERVICE_API_ENDPOINT || 'ds-flex-service/v1';

const serviceApi = {
  getRepairOrderById: id =>
    makeReq({
      url: `${SERVICE_API_ENDPOINT}/repair-orders/${id}`,
      method: 'GET'
    }),
  saveRepairOrderPayment: (roNum, cashieringDetail) =>
    makeReq({
      url: `${SERVICE_API_ENDPOINT}/repair-orders/${roNum}/customer-payments`,
      method: 'PUT',
      data: cashieringDetail
    }),
  getRepairOrderPayment: roNum =>
    makeReq({
      url: `${SERVICE_API_ENDPOINT}/repair-orders/${roNum}/customer-payments`,
      method: 'GET'
    }),
  getPaymentSetupForPaymentType(paymentType) {
    return makeReq({
      url: `${SERVICE_API_ENDPOINT}/payment-types/${paymentType}`,
      method: 'GET'
    });
  },
  getPaymentTypes() {
    return makeReq({
      url: `${SERVICE_API_ENDPOINT}/payment-types`,
      method: 'GET'
    });
  }
};

export default serviceApi;
