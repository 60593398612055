import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';
import '@cdk-uip/icons';
import '@cdk-uip/react/cdk.css';
import App from './App.js';
import 'assets/styles'; // Import this first to avoid selector-order CSS issues
import { Provider } from 'react-redux';
import store from 'redux-store'; // Import store last (avoids circular dependency nightmare)

// Render the app
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
