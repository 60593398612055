import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import _ from 'lodash';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import classnames from 'classnames';
import { Loader } from 'cdk-radial';
import {
  constants,
  withPermissions,
  withSettings
} from '@cdk-flex/components-flex';
import { withFlags } from '@cdk-flex/components-launch-darkly';
import { ErrorBanner } from '@cdk-flex/components-error';
import {
  getVehicleCostJournalEntry,
  InventoryVehicleForm,
  VehicleCost
} from '@cdk-flex/components-vehicle-form';
import { VehicleHeader, VehicleTabs } from './components';
import { accounting as accountingApi, vehiclesApi } from 'api/resources';
import { ConditionallyRendered, getVehicleId } from 'utils';
import i18nMessages from '../../i18nMessages';
import {
  CREATE_VEHICLE,
  getDefaultUICostDetails,
  defaultUIVehicle,
  ERROR_TYPES,
  getErrorStringFromErrorsObject,
  getIsVehicleUsedRentalOrMisc,
  LOADING,
  NEW_JOURNAL,
  normalizeCostDetailsUIToApi,
  normalizeVehicleApiToUI,
  normalizeVehicleUIToApi,
  READ_COST,
  SAVING,
  UPDATE_COST,
  USED_JOURNAL,
  validateCostDetails
} from '../../utils';
import './style.less';

const VehicleDetails = ({
  flags,
  history,
  intl,
  match,
  permissions,
  settings
}) => {
  const [originalVehicle, setOriginalVehicle] = useState(defaultUIVehicle);
  const [originalCostDetails, setOriginalCostDetails] = useState(
    getDefaultUICostDetails(defaultUIVehicle, settings)
  );

  const [formData, setFormData] = useState({ accounts: [], journals: [] });
  const [formState, setFormState] = useState('');
  const [formLevelError, setFormLevelError] = useState(null);

  const [vehicleCostErrors, setVehicleCostErrors] = useState({});

  const hasAccessToVehicleCost =
    permissions.includes(READ_COST) || permissions.includes(UPDATE_COST);

  useEffect(() => {
    const vehicleStockNumber = _.get(match, 'params.id', '');
    if (vehicleStockNumber) {
      fetchVehicleData(vehicleStockNumber);
    }
    fetchFormData();
  }, []);

  const fetchFormData = async () => {
    try {
      const glCoaData = await accountingApi.getGlCoaData();
      const allJournals = await accountingApi.getJournalNumbers();

      setFormData({ accounts: glCoaData, journals: allJournals });
    } catch (e) {
      setFormLevelError({
        errorMessage: e,
        errorType: ERROR_TYPES.apiError
      });
    }
  };

  const fetchVehicleData = async stockNumber => {
    setFormState(LOADING);
    try {
      const response = await vehiclesApi.getInvVehicle(stockNumber);
      const vehicle = normalizeVehicleApiToUI({
        ...defaultUIVehicle,
        ...response
      });
      const preFilledCostDetails = getDefaultUICostDetails(vehicle, settings);
      setOriginalVehicle(vehicle);
      if (hasAccessToVehicleCost) {
        const costDetails = await vehiclesApi.getAccounting(stockNumber);
        setOriginalCostDetails({ ...preFilledCostDetails, ...costDetails });
      }
    } catch (e) {
      setFormLevelError({
        errorMessage: (
          <FormattedMessage
            {...i18nMessages.unableToLoadStockNumberVehicle}
            values={{ stockNumber }}
          />
        ),
        retry: () => fetchVehicleData(stockNumber)
      });
    } finally {
      setFormState('');
    }
  };

  const handleSave = async (
    { vehicle, costDetails },
    postToAccounting = false,
    setFieldValue = null
  ) => {
    setFormState(SAVING);
    try {
      const normalizedVehicle = normalizeVehicleUIToApi(vehicle);
      const normalizedCostDetails = normalizeCostDetailsUIToApi(costDetails);

      if (vehicle.id) {
        await vehiclesApi.updateInvVehicle(normalizedVehicle);
      } else {
        await vehiclesApi.createInvVehicle(normalizedVehicle);
        // in case subsequent api failures, we can re-try those api calls without trying re-create a vehicle that had successfully been created
        const id = getVehicleId(vehicle);
        if (setFieldValue) setFieldValue('vehicle', { ...vehicle, id });
      }
      if (permissions.includes(UPDATE_COST)) {
        await vehiclesApi.updateAccounting(
          normalizedCostDetails,
          normalizedVehicle.stockNumber
        );
      }
      if (permissions.includes(UPDATE_COST) && postToAccounting) {
        const journalEntry = getVehicleCostJournalEntry({
          accounts: formData.accounts,
          costDetails,
          journals: formData.journals,
          vehicle
        });
        await accountingApi.postDocument(journalEntry, 'GENERAL_LEDGER');
      }

      if (postToAccounting) {
        fetchVehicleData(normalizedVehicle.stockNumber);
        history.push(`/vehicle/${normalizedVehicle.stockNumber}`);
      } else {
        history.push('/vehicles/inventory');
      }
    } catch (e) {
      setFormLevelError({
        errorMessage: e,
        errorType: ERROR_TYPES.apiError
      });
    } finally {
      setFormState('');
    }
  };

  return (
    <Formik
      // the vehicle object gets validated for both onSave and onPost, however, the cost details object only gets validated onPost
      validationSchema={Yup.object().shape({
        vehicle: Yup.object().shape({
          vin: Yup.string().required(),
          odometer: Yup.string().required(),
          year: Yup.string().required(),
          make: Yup.object().shape({
            abbreviation: Yup.string().required()
          }),
          model: Yup.object().shape({
            abbreviation: Yup.string().required()
          }),
          exteriorColor: Yup.string().required(),
          stockNumber: Yup.string().required(),
          inventoryDetails: Yup.object().shape({
            acquisitionType: Yup.string().required()
          }),
          cost: Yup.object().shape({
            inventoryAccountNumber: Yup.string().required()
          })
        })
      })}
      enableReinitialize
      initialValues={{
        vehicle: originalVehicle,
        costDetails: originalCostDetails
      }}
      onSubmit={values => handleSave(values, false)}
    >
      {({
        errors,
        setFieldValue,
        setValues,
        submitForm,
        validateForm,
        values,
        setTouched,
        touched
      }) => {
        const fieldLevelErrors =
          _.get(formLevelError, 'errorType', '') ===
          ERROR_TYPES.formValidationError
            ? errors
            : {};

        const handleSetVehicle = vehicle => {
          setTouched({ ...touched, vehicle: true });
          setValues({
            vehicle,
            costDetails: !touched.costDetails
              ? {
                  ...values.costDetails,
                  journalDetails: {
                    ...values.costDetails.journalDetails,
                    journalId: getIsVehicleUsedRentalOrMisc(vehicle)
                      ? USED_JOURNAL
                      : NEW_JOURNAL
                  },
                  destinationAccounts: {
                    ...values.costDetails.destinationAccounts,
                    inventoryCost: _.get(
                      vehicle,
                      'cost.inventoryAccountNumber',
                      ''
                    ),
                    dealerCost: _.get(
                      vehicle,
                      'cost.inventoryAccountNumber',
                      ''
                    )
                  }
                }
              : values.costDetails
          });
        };

        return (
          <div
            className={classnames('vehicle-details', {
              'error-banner-deployed': !!formLevelError
            })}
          >
            <VehicleHeader
              isSaving={formState === SAVING}
              onSave={() => {
                validateForm().then(bannerErrors => {
                  if (!_.isEmpty(bannerErrors)) {
                    setFormLevelError({
                      errorMessage: getErrorStringFromErrorsObject(
                        bannerErrors,
                        intl
                      ),
                      errorType: ERROR_TYPES.formValidationError
                    });
                  } else {
                    submitForm();
                  }
                });
              }}
              vehicle={_.get(values, 'vehicle', {})}
            />
            <ConditionallyRendered isRendered={formLevelError}>
              <ErrorBanner
                onCancel={() => setFormLevelError(null)}
                onRetry={formLevelError && formLevelError.retry}
                cancelButtonText={
                  <FormattedMessage {...i18nMessages.dismiss} />
                }
              >
                {formLevelError && formLevelError.errorMessage}
              </ErrorBanner>
            </ConditionallyRendered>
            <ConditionallyRendered isRendered={formState === LOADING}>
              <Loader />
            </ConditionallyRendered>
            <ConditionallyRendered isRendered={formState !== LOADING}>
              <VehicleTabs
                vehicleDetailsTab={
                  <InventoryVehicleForm
                    locale={intl.locale}
                    onSetVehicle={handleSetVehicle}
                    vehicle={values.vehicle}
                    errors={fieldLevelErrors.vehicle}
                    accounts={formData.accounts}
                    flags={flags}
                    handleError={setFormLevelError}
                    permissions={permissions}
                    isReadOnly={!permissions.includes(CREATE_VEHICLE)}
                    isFactoryOptionsEditable={_.get(
                      values,
                      'costDetails.accountingDetail.allowPosting',
                      false
                    )}
                  />
                }
                hasAccessToVehicleCost={hasAccessToVehicleCost}
                costAndPriceTab={
                  <VehicleCost
                    locale={intl.locale}
                    onSetVehicle={vehicle => setFieldValue('vehicle', vehicle)}
                    onSetCostDetails={costDetails => {
                      setTouched({ ...touched, costDetails: true });
                      setFieldValue('costDetails', costDetails);
                    }}
                    onPost={() => {
                      const costErrors = validateCostDetails(values);
                      setVehicleCostErrors(costErrors);
                      validateForm().then(bannerErrors => {
                        const formErrors = { ...bannerErrors, ...costErrors };
                        if (!_.isEmpty(formErrors)) {
                          setFormLevelError({
                            errorMessage: getErrorStringFromErrorsObject(
                              formErrors,
                              intl
                            ),
                            errorType: ERROR_TYPES.formValidationError
                          });
                        } else {
                          handleSave(values, true, setFieldValue);
                        }
                      });
                    }}
                    accounts={formData.accounts}
                    journals={formData.journals}
                    vehicle={values.vehicle}
                    costDetails={values.costDetails}
                    settings={_.get(settings, 'dealerCostCalculation', {})}
                    isPosting={formState === SAVING}
                    errors={vehicleCostErrors}
                    isReadOnly={!permissions.includes(UPDATE_COST)}
                  />
                }
              />
            </ConditionallyRendered>
          </div>
        );
      }}
    </Formik>
  );
};

VehicleDetails.propTypes = {
  flags: shape({
    coreLotManagement: bool
  }),
  history: shape({
    push: func
  }).isRequired,
  intl: intlShape.isRequired,
  match: shape({
    params: shape({
      id: string
    })
  }).isRequired,
  permissions: arrayOf(string),
  settings: shape({
    dealerCostCalculation: shape({
      includeAdvertising: bool,
      includeHoldback: bool
    }),
    defaultAdvertisingAccount: string,
    defaultDealerHoldBackAccount: string
  })
};

VehicleDetails.defaultProps = {
  flags: {
    coreLotManagement: false
  },
  permissions: [],
  settings: {
    dealerCostCalculation: {
      includeAdvertising: true,
      includeHoldback: true
    },
    defaultAdvertisingAccount: '',
    defaultDealerHoldBackAccount: ''
  }
};

export default injectIntl(
  withFlags(withPermissions(withSettings(constants.VEHICLE)(VehicleDetails)))
);
