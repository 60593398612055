import React from 'react';
import { PageHeader } from '@cdk-flex/components-page';
import { Card, CardMedia, CardMediaContent } from '@cdk-uip/react-card';
import { Theme } from '@cdk-uip/react-theme';
import { Headline6 } from '@cdk-uip/react-typography';
import { FormattedMessage } from 'react-intl';

const HomePageHeader = () => {
  const currentHours = new Date().getHours();
  const loggedInUser = JSON.parse(window.sessionStorage.getItem('user'));
  const impersonatingUser = JSON.parse(
    window.sessionStorage.getItem('im_user')
  );

  const greeting = () => {
    if (currentHours < 12)
      return <FormattedMessage id="Morning" defaultMessage="Good Morning" />;
    if (currentHours < 18)
      return (
        <FormattedMessage id="Afternoon" defaultMessage="Good Afternoon" />
      );
    return <FormattedMessage id="Evening" defaultMessage="Good Evening" />;
  };

  return (
    <PageHeader className="homePageHeader">
      <Card>
        <CardMedia className="homePageHeaderCardMedia" scale="16-9">
          <CardMediaContent>
            <Theme text="text-primary-on-dark">
              <Headline6 className="homePageHeadline" margins={false}>
                <div className="homePageGreeting">
                  {impersonatingUser ? (
                    <FormattedMessage
                      id="HomeGreeting.Impersonated"
                      defaultMessage="{greeting}, {firstName} (Temp Role Matching {imFirstName} {imLastName}){exclamation}"
                      values={{
                        greeting: greeting(),
                        firstName: loggedInUser.profile.given_name,
                        imFirstName:
                          impersonatingUser &&
                          impersonatingUser.name &&
                          impersonatingUser.name.first,
                        imLastName:
                          impersonatingUser &&
                          impersonatingUser.name &&
                          impersonatingUser.name.last,
                        exclamation: (
                          <FormattedMessage
                            id="Exclamation"
                            defaultMessage="!"
                          />
                        )
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      id="HomeGreeting"
                      defaultMessage="{greeting}, {firstName}{exclamation}"
                      values={{
                        greeting: greeting(),
                        firstName: loggedInUser.profile.given_name,
                        exclamation: (
                          <FormattedMessage
                            id="Exclamation"
                            defaultMessage="!"
                          />
                        )
                      }}
                    />
                  )}
                </div>
              </Headline6>
            </Theme>
          </CardMediaContent>
        </CardMedia>
      </Card>
    </PageHeader>
  );
};

export default HomePageHeader;
