import React, { useState } from 'react';
import { bool, node } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Tabs, Tab, Panel } from 'cdk-radial';
import { ConditionallyRendered } from 'utils';
import i18nMessages from '../../../i18nMessages';

const VehicleTabs = ({
  costAndPriceTab,
  hasAccessToVehicleCost,
  intl,
  vehicleDetailsTab
}) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  return (
    <div className="vehicle-tabs">
      <ConditionallyRendered isRendered={hasAccessToVehicleCost}>
        <Tabs
          activeItemIndex={activeItemIndex}
          items={[
            {
              id: 'inventory-vehicle-form-tab',
              panelId: 'inventory-vehicle-form-panel',
              renderPanel: props => (
                <Panel {...props}>{vehicleDetailsTab}</Panel>
              ),
              renderTab: props => (
                <Tab
                  label={<FormattedMessage {...i18nMessages.vehicleDetails} />}
                  {...props}
                />
              )
            },
            {
              id: 'vehicle-cost-tab',
              panelId: 'vehicle-cost-panel',
              renderPanel: props => <Panel {...props}>{costAndPriceTab}</Panel>,
              renderTab: props => (
                <Tab
                  label={<FormattedMessage {...i18nMessages.priceAndCost} />}
                  {...props}
                />
              )
            }
          ]}
          onActiveItemChange={setActiveItemIndex}
          tabsLabel={intl.formatMessage(
            i18nMessages.tabBetweenVehicleDetailsVehicleCost
          )}
        />
      </ConditionallyRendered>
      <ConditionallyRendered isRendered={!hasAccessToVehicleCost}>
        {vehicleDetailsTab}
      </ConditionallyRendered>
    </div>
  );
};

VehicleTabs.propTypes = {
  costAndPriceTab: node,
  hasAccessToVehicleCost: bool,
  intl: intlShape.isRequired,
  vehicleDetailsTab: node
};

VehicleTabs.defaultProps = {
  costAndPriceTab: <span />,
  hasAccessToVehicleCost: true,
  vehicleDetailsTab: <span />
};

export default injectIntl(VehicleTabs);
