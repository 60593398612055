import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CardActions,
  CardActionButtons,
  CardActionButton,
  CardActionIcons
} from '@cdk-uip/react-card';
import { Menu, MenuItem, MenuAnchor } from '@cdk-uip/react-menu';
import { Icon } from '@cdk-uip/react-icon';
import { withPermissions } from '@cdk-flex/components-flex';
import { logNavigationEvent } from '@cdk-flex/components-flex-amplitude';

const ROOT_PATH = process.env.PUBLIC_URL;

const QuickLinks = ({ quicklinks, permissions }) => {
  const [isOpen, setOpen] = useState(false);

  const menuOpen = ev => {
    ev.preventDefault();
    setOpen(true);
  };

  const filteredLinks = quicklinks.filter(link => {
    const { permissionId, permissionFn = () => true } = link;
    return permissionId
      ? permissions.includes(permissionId)
      : permissionFn(permissions);
  });

  const [main, sub] = [filteredLinks.slice(0, 3), filteredLinks.slice(3)];

  const openPage = link => {
    window.location = `${ROOT_PATH}/${link}`;
  };

  return (
    <CardActions className="quick-links">
      <CardActionButtons>
        {main.map(l => (
          <button
            key={l.linkName}
            className="quick-link"
            onClick={e =>
              logNavigationEvent('dashboard', `/${l.link}`, () =>
                openPage(l.link)
              )
            }
          >
            <CardActionButton>{l.linkName}</CardActionButton>
          </button>
        ))}
      </CardActionButtons>
      {sub.length > 0 && (
        <CardActionIcons>
          <MenuAnchor>
            <Icon onClick={menuOpen}>more_vert</Icon>
            <Menu open={isOpen} onCancel={() => setOpen(false)}>
              {sub.map(l => (
                <MenuItem key={l.linkName}>
                  <button
                    className="quick-link"
                    onClick={e =>
                      logNavigationEvent('dashboard', `/${l.link}`, () =>
                        openPage(l.link)
                      )
                    }
                  >
                    {l.linkName}
                  </button>
                </MenuItem>
              ))}
            </Menu>
          </MenuAnchor>
        </CardActionIcons>
      )}
    </CardActions>
  );
};

QuickLinks.propTypes = {
  quicklinks: PropTypes.arrayOf(PropTypes.any).isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default withPermissions(QuickLinks);
