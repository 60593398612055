import { ApiHandler2 as makeReq } from '@cdk-flex/components-api-handler';
import { getVehicleId } from 'utils';

const VEHICLE_BASE_URL =
  process.env.REACT_APP_VEHICLE_API_ENDPOINT || 'ds-flex-vehicle/v1';

const endpoint = `${VEHICLE_BASE_URL}/vehicles`;

const vehicleAPI = {
  searchVehicles(params) {
    return makeReq({
      url: `${endpoint}/inventory/search`,
      method: 'GET',
      params
    });
  },

  getInvVehicle(stockId = null) {
    return makeReq({
      url: `${endpoint}/inventory/${stockId}`,
      method: 'GET'
    });
  },

  getVehicle(id = null) {
    return makeReq({
      url: `${endpoint}/${id}`,
      method: 'GET'
    });
  },

  updateInvVehicle(data) {
    return makeReq({
      method: 'POST',
      url: `${endpoint}/inventory/update/${data.stockNumber}`,
      data
    });
  },

  createInvVehicle(data) {
    // ID is last 8 characters of VIN
    const id = getVehicleId(data);
    return makeReq({
      method: 'POST',
      url: `${endpoint}/inventory/create`,
      data: {
        ...data,
        id
      }
    });
  },

  getAccounting(stockNumber = null) {
    return makeReq({
      method: 'GET',
      url: `${endpoint}/inventory/${stockNumber}/costs`
    });
  },

  updateAccounting(data, stockNumber) {
    return makeReq({
      method: 'POST',
      url: `${endpoint}/inventory/update/${stockNumber}/accounting`,
      data
    });
  },
  getVehicleService(id) {
    return makeReq({
      method: 'GET',
      url: `${endpoint}/service/${id}`
    });
  },
  updateStockNumber: stockNumber =>
    makeReq({
      url: `${endpoint}/stock-number/${stockNumber} `,
      method: 'GET'
    })
};

export default vehicleAPI;
