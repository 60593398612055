import { isEmpty } from 'lodash';
const DEFAULT_ERROR_MESSAGE = 'Unable to handle API response';
// Extracts error messages to display from the error response.
export const getErrorMessage = err => {
  /** Example error responses:
   *  1. {"errors":[null]}
   */
  if (typeof err === 'string') {
    return err;
  }

  if (err.errors)
    return err.errors
      ? err.errors
          .map(curr => (curr === null ? DEFAULT_ERROR_MESSAGE : curr))
          .reduce((acc, curr) => acc.concat(curr), [])
          .join(',')
      : '';
  // Example error response: {"errors":[null]}
  else return `${DEFAULT_ERROR_MESSAGE}: ${JSON.stringify(err)}`;
};

export const getExceptionTextMessage = async err => {
  let errorMessage = '';
  if (err.text) {
    const res = await err.text();
    let data = JSON.parse(res);
    if (data && data.name === 'VALIDATION') {
      if (!isEmpty(data.issues)) {
        data.issues.forEach(item => {
          errorMessage += item.issue;
        });
      }
    }
  }
  return errorMessage;
};
