import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import {
  IconChevronLeft,
  PageHeaderButtonGroup,
  PageHeaderIconLink,
  PageHeaderTitleBlock,
  PageHeaderWrapper
} from 'cdk-radial';
import i18nMessages from '../../../i18nMessages';

const getVehicleTitle = vehicle => {
  const exteriorColor = _.get(vehicle, 'exteriorColor', '');
  const title = `${_.get(vehicle, 'year', '')} ${_.get(
    vehicle,
    'model.name',
    ''
  )} ${exteriorColor ? `(${exteriorColor})` : ''}`;
  if (!title.trim()) return <FormattedMessage {...i18nMessages.addVehicle} />;
  return title;
};

const VehicleHeader = ({ isSaving, onSave, vehicle }) => (
  <PageHeaderWrapper className="vehicle-header">
    <PageHeaderIconLink href="/vehicles/inventory" icon={<IconChevronLeft />} />
    <PageHeaderTitleBlock title={getVehicleTitle(vehicle)} />
    <PageHeaderButtonGroup
      alignment="right"
      buttonsProps={[
        ...(_.get(vehicle, 'id', '') && !isSaving
          ? [
              {
                id: 'start-deal-button',
                href: `/sales/deals/create?stockNumber=${vehicle.stockNumber}`,
                text: <FormattedMessage {...i18nMessages.startDeal} />,
                variant: 'secondary'
              },
              {
                id: 'start-ro-button',
                href: `/service/repair-order/new?stockNumber=${vehicle.stockNumber}`,
                text: <FormattedMessage {...i18nMessages.startRepairOrder} />,
                variant: 'secondary'
              }
            ]
          : []),
        {
          id: 'save-button',
          onClick: onSave,
          text: <FormattedMessage {...i18nMessages.save} />,
          variant: 'primary',
          isLoading: isSaving
        }
      ]}
    />
  </PageHeaderWrapper>
);

VehicleHeader.propTypes = {
  isSaving: bool,
  onSave: func.isRequired,
  vehicle: shape({
    exteriorColor: string,
    id: string,
    model: shape({
      name: string
    }),
    stockNumber: string,
    year: string
  })
};

VehicleHeader.defaultProps = {
  isSaving: false,
  vehicle: {
    exteriorColor: '',
    id: '',
    model: {
      name: ''
    },
    stockNumber: '',
    year: ''
  }
};

export default VehicleHeader;
