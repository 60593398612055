import { ApiHandler2 as makeReq } from '@cdk-flex/components-api-handler';

const ONE_PAY_URL_BASE =
  process.env.REACT_APP_ONEPAY_API_ENDPOINT || 'ds-flex-onepay/v1';
const accountingURLEndpoint = `${ONE_PAY_URL_BASE}/payment-url/accounting/`;

const onePayApi = {
  getGlobalEntityId: storeId =>
    makeReq({
      url: `${ONE_PAY_URL_BASE}/globalentityid/${storeId}`,
      method: 'GET'
    }),
  getAdminURL: (storeId, userId) =>
    makeReq({
      url: `${ONE_PAY_URL_BASE}/admin-url/${storeId}/${userId}`,
      method: 'GET'
    }),
  getPaymentMappings: (globalEntityId, account) =>
    makeReq({
      url: `${ONE_PAY_URL_BASE}/paymentmappings/${globalEntityId}/${account}`,
      method: 'GET'
    }),
  getDepartmentCode: invoiceId =>
    makeReq({
      url: `${ONE_PAY_URL_BASE}/department/logon-name/${invoiceId}`,
      method: 'GET'
    }),
  getDepartments: globalEntityId =>
    makeReq({
      url: `${ONE_PAY_URL_BASE}/department/list/${globalEntityId}`,
      method: 'GET'
    }),
  createOnePayURL: async paymentInfo =>
    makeReq({
      method: 'POST',
      url: accountingURLEndpoint,
      data: paymentInfo
    })
};

export default onePayApi;
