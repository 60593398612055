import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createReducer } from './reducers';

// Support Redux dev tools Chrome extension
/* eslint-disable */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable */

const configureStore = initialState => {
  const store = createStore(
    createReducer(),
    initialState,
    composeEnhancers(applyMiddleware(thunk))
  );
  store.asyncReducers = {};
  store.initialState = initialState;
  return store;
};

const store = configureStore();

export const loadAsyncReducer = (name, asyncReducer) => {
  if (!store.asyncReducers[name]) {
    store.asyncReducers[name] = asyncReducer;
    store.replaceReducer(
      createReducer(store.asyncReducers, store.initialState)
    );
  }
};

// // we'll use this when we need to hydrate the store or something similar
// const initialState = {};

// Export the created store
export default store;
