import React from 'react';
import { FormattedMessage } from 'react-intl';

const voidedRepairOrdersLink = [{
    id: 'Voided Repair Orders',
    linkName: (
        <FormattedMessage
            id="FLEX.CORE.DASHBOARD.SERVICE.LINKNAME.VOIDED-REPAIR-ORDERS"
            defaultMessage="Voided Repair Orders"
        />
    ),
    link: 'service/sa-dashboard/voided-repairs',
    permissionId: 'READ_RO'
}];

const getServiceCard = (serviceShowVoidedRoReport) => {
    return {
        cardText: (
            <FormattedMessage
                id="FLEX.CORE.DASHBOARD.SERVICE.CARDTEXT.SERVICE"
                defaultMessage="Service"
            />
        ),
        avatarText: (
            <FormattedMessage
                id="FLEX.CORE.DASHBOARD.SERVICE.AVATAR-TEXT"
                defaultMessage="Se"
            />
        ),
        link: 'service/sa-dashboard',
        permissionId: 'SERVICE_FLEX',
        quickLinks: [
            {
                id: 'Appointments',
                linkName: (
                    <FormattedMessage
                        id="FLEX.CORE.DASHBOARD.SERVICE.LINKNAME.APPOINTMENTS"
                        defaultMessage="Appointments"
                    />
                ),
                link: 'service/sa-dashboard/appointments',
                permissionId: 'READ_APPOINTMENT'
            },
            {
                id: 'Open Repair Orders',
                linkName: (
                    <FormattedMessage
                        id="FLEX.CORE.DASHBOARD.SERVICE.LINKNAME.OPEN-REPAIRS"
                        defaultMessage="Open Repairs"
                    />
                ),
                link: 'service/sa-dashboard/open-repairs',
                permissionId: 'READ_RO'
            },
            {
                id: 'Start Walk-in',
                linkName: (
                    <FormattedMessage
                        id="FLEX.CORE.DASHBOARD.SERVICE.LINKNAME.START-WALK-IN"
                        defaultMessage="Start Walk-In"
                    />
                ),
                link: 'service/repair-order/new',
                permissionId: 'CREATE_RO'
            },
            {
                id: 'Repair History',
                linkName: (
                    <FormattedMessage
                        id="FLEX.CORE.DASHBOARD.SERVICE.LINKNAME.SERVICE-HISTORY"
                        defaultMessage="Service History"
                    />
                ),
                link: 'service/sa-dashboard/repair-history',
                permissionId: 'READ_RO'
            },
            ...(serviceShowVoidedRoReport ? voidedRepairOrdersLink : []),
            {
                id: 'OEM Service',
                linkName: (
                    <FormattedMessage
                        id="FLEX.CORE.DASHBOARD.ADMINISTRATION.LINKNAME.OEMSERVICE"
                        defaultMessage="OEM - Service"
                    />
                ),
                link: 'service/oem',
                permissionId: 'SERVICE_FLEX'
            }
        ]
    }
};

export const serviceCard = (flags) => {
    const { serviceShowVoidedRoReport } = flags;
    return getServiceCard(serviceShowVoidedRoReport);
};