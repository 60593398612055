import { ApiHandler as makeReq } from '@cdk-flex/components-api-handler';

const SERVICE_BASE_URL = process.env.REACT_APP_SERVICE_API_ENDPOINT;

const servicePricingOperationsApi = {
  getMakes(franchiseName) {
    return makeReq({
      url: `${SERVICE_BASE_URL}/dealership-setup/franchises`,
      method: 'GET',
      params: {
        franchiseName
      }
    });
  },
  getLaborTypes() {
    return makeReq({
      url: `${SERVICE_BASE_URL}/labor-types`,
      method: 'GET'
    });
  },
  getModelsByMake(makeId) {
    return makeReq({
      url: `${SERVICE_BASE_URL}/vehicle-specifications/makes/${makeId}`,
      method: 'GET'
    });
  },
  getYearsByModel(makeId, modelId) {
    return makeReq({
      url: `${SERVICE_BASE_URL}/vehicle-specifications/makes/${makeId}/models/${modelId}/variants`,
      method: 'GET'
    });
  },

  getYearsByMultipleModel(makeId, customizeVehModels) {
    return makeReq({
      url: `${SERVICE_BASE_URL}/vehicle-specifications/makes/${makeId}/variants`,
      method: 'POST',
      data: customizeVehModels
    });
  },
  createDealerPackage(operation) {
    return makeReq({
      url: `${SERVICE_BASE_URL}/pricing/dealer-defined-packages`,
      method: 'POST',
      data: operation
    });
  },

  deleteDealerCombination(dealerPackageId, combinationToDelete) {
    return makeReq({
      url: `${SERVICE_BASE_URL}/pricing/delete-packages/${dealerPackageId}`,
      method: 'DELETE',
      data: combinationToDelete
    });
  },

  updateDealerPackage(operation) {
    return makeReq({
      url: `${SERVICE_BASE_URL}/pricing/dealer-defined-packages/${operation.servicePackageId}`,
      method: 'POST',
      data: operation
    });
  },
  updateOEMPackage(operation) {
    return makeReq({
      url: `${SERVICE_BASE_URL}/pricing/oem-packages/${operation.servicePackageId}/labor-part-customization`,
      method: 'POST',
      data: operation
    });
  },
  getParts(searchText) {
    return makeReq({
      url: `${SERVICE_BASE_URL}/parts/search/${searchText}`,
      method: 'GET'
    });
  },
  getServicePackage(servicePackageId, makeId, type) {
    return makeReq({
      url: `${SERVICE_BASE_URL}/pricing/packages/${servicePackageId}/makes/${makeId}/package-types/${type}`,
      method: 'GET'
    });
  },
  getSpsOperations(makeId = 'CHEV') {
    return makeReq({
      url: `${SERVICE_BASE_URL}/pricing/packages`,
      method: 'GET',
      params: {
        makeId
      }
    });
  },
  getSearchedSpsOperations(searchText, makeId = 'CHEV') {
    return makeReq({
      url: `${SERVICE_BASE_URL}/pricing/packages/makes/${makeId}/search`,
      method: 'GET',
      params: {
        text: searchText
      }
    });
  },
  deleteDealerOperaton(vehicleSpecId, servicePackageId) {
    return makeReq({
      url: `${SERVICE_BASE_URL}/pricing/vehicle-specifications/${vehicleSpecId}---US/packages/${servicePackageId}`,
      method: 'DELETE'
    });
  },
  getSPSVehicleSectionCodes(makeId) {
    return makeReq({
      url: `${SERVICE_BASE_URL}/vehicle-sections/makes/${makeId}/sections`,
      method: 'GET'
    });
  },
  checkSPSOpcodeExists(servicePackageId, makeId) {
    return makeReq({
      url: `${SERVICE_BASE_URL}/pricing/exists/${servicePackageId}/makes/${makeId}`,
      method: 'GET'
    });
  }
};

export default servicePricingOperationsApi;
